import Header from "../Components/Header";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
export default function Video() {
        const { t } = useTranslation();
        useEffect(() => {
                AOS.init();
              }, [])
    return<>
        <section className=" flex hpl mr-200 ">
        <div className="header wd-100" >
   <h1><span>|</span> {t("Video")}</h1>
</div>
            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4></h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
     <div className="shadow">
     <h4></h4>
             </div>              
</div>

<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800"  style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
     <div className="shadow">
     <h4></h4>
             </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
     <div className="shadow">
     <h4></h4>
             </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4></h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
     <div className="shadow">
     <h4></h4>
             </div>              
</div>

<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800"  style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
     <div className="shadow">
     <h4></h4>
             </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
     <div className="shadow">
     <h4></h4>
             </div>              
</div>
</section>
    </>
}