import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import $ from 'jquery';

export default function WhyRez() {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
        var windowHeight = $(window).height();
        $('.div1').height(windowHeight);     
      }, [])
    return<>
    <div className="whystaron ">
    <div class="div1">
    <div className="shadow">
        
    </div>

    <div className="social">
    <h1>{t("Why")} {t("Radianz")}</h1>
        <p>{t("rezp")}</p>
    </div>
  </div>
{/* <div className="brousore flex flex-3 mr-100">
    
<div className="header ">
   <h1><span>|</span>{t("Brochures")}</h1>
</div>
<div className="item" data-aos="fade-up"
     data-aos-anchor-placement="top-center"data-aos-duration="600">
    <div className="bg" style={{backgroundImage: `url(${require("../Assets/prev.png")})`}}>

    </div>
    <h1>Previous Projects</h1>
    <a href="http://localhost:3000/static/media/prevproject.d108044b200e06020298.pdf">{t("download")}</a>
</div>
<div className="item" data-aos="fade-up"
     data-aos-anchor-placement="top-center"data-aos-duration="800">
    <div className="bg" style={{backgroundImage: `url(${require("../Assets/company.png")})`}}>

    </div>
    <h1>company profile</h1>
    <a href="http://localhost:3000/static/media/CP.fac93abd1ef787fa3c87.pdf">{t("download")}</a>
</div>
</div> */}
    
    </div>
   
    </>
}