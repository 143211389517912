import Header from "../Components/Header";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Fancybox from "../Components/Fancybox";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";

import { Autoplay ,Navigation } from 'swiper/modules';
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function PRIMO(params){
    const [cc,Setcc]=useState(1);
    const { t } = useTranslation();
    useEffect(() => {
      AOS.init();
    }, [])
    return<>
       <Header  head="Primo SD"bg="uploads/2022/04/IN_HP_Primo_SD_21012561_001.jpg" paragraph="Primo SD is the ideal permanent static-dissipative flooring for use in laboratories and ESD-sensitive areas in hospitals. It provides a perfect combination of stable and reliable static dissipation alongside a pre-treatment which provides a base for future maintenance procedures.. Primo SD is available in a non-linear pattern with colours coordinated with the other products and accessories of the Premium multi-solution family." />
       <div className="brousore flex flex-2   mr-100" >
    
    <div className="header ">
   <h1><span>|</span>{t("category")} {t("details")}</h1>
    <Fancybox
  options={{
    Carousel: {
      infinite: false,
    },
  }}
>
    
<Swiper
  slidesPerView={cc}
  centeredSlides={true}
  pagination={{
    clickable: true,
    
  }}
  autoplay={{
      delay: 1000,
      disableOnInteraction: false,
    }}
    
  loop
  navigation={true}
  modules={[ Autoplay,Navigation ]}
  className="mySwiper-1 "
><SwiperSlide>
  <a data-fancybox="gallery"data-caption="IN_HP_RUBY70"  href={require("../Assets/uploads/2022/04/IN_HP_RUBY70_25145068_25098075_001-500x281.jpg")}>
    <img className="gallery" src={require("../Assets/uploads/2022/04/IN_HP_Primo_SD_21012561_001.jpg")} width="200" height="150" />

  </a>
  </SwiperSlide>
  </Swiper> 
</Fancybox>

    </div>
    <div className="item  dimensions">
    <Tabs
      defaultActiveKey="KEYFEATURES"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="KEYFEATURES" title={t("KEY_FEATURES")}>



<ul>
    
<li data-aos="fade-up" data-aos-duration="500">{t("STANDARD_KEY_FEATURES_d1")}</li>
      <li data-aos="fade-up" data-aos-duration="600">{t("STANDARD_KEY_FEATURES_d2")}</li>
      <li data-aos="fade-up" data-aos-duration="700">{t("STANDARD_KEY_FEATURES_d3")}</li>
      </ul>      </Tab>
<Tab eventKey="TECHNICALSPECIFICATIONS" title={t("TECHNICAL_SPECIFICATIONS")}>
<ul><li><strong>{t("Product_type")}:</strong> {t("producttypep")}</li>
<li><strong>{t("Commercial_classification")}:&nbsp;</strong> {t("Commercial_classification_p")}</li>
<li><strong>{t("Total_thickness")}:</strong>&nbsp;3 mm</li>
<li><strong>{t("Wear_layer_thickness")}:&nbsp;</strong>0,70 mm</li>
<li><strong>{t("Total_weight")}:</strong>&nbsp;2500 g/m²</li></ul>    
  </Tab>
    </Tabs>
    </div>
   
    </div>
<div className="brousore flex flex-3 mr-100">
    
<div className="header ">
   <h1><span>|</span>{t("our documents")}</h1>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>BROCHURE</h2>
    <a href={require("../Assets/uploads/2022/04/brochure1-1.pdf")}  target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>

<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>COVER</h2>
    <a href={require("../Assets/uploads/2022/04/IN_HP_Eclipse_Premium_21020968_21020967_001-scaled.jpg")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>

<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>DATASHEET</h2>
    <a href={require("../Assets/uploads/2022/04/datasheet.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>MAINTENANCE</h2>
    <a href={require("../Assets/uploads/2022/04/Maintenance.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>ENVIRONMENTAL PRODUCT DECLARATION</h2>
    <a href={require("../Assets/uploads/2022/04/environmental-product.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>MATERIAL HEALTH STATEMENT</h2>
    <a href={require("../Assets/uploads/2022/04/Material-health-statement-1.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>

<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>INSTALLATION</h2>
    <a href={require("../Assets/uploads/2022/04/installation.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
</div>
    

    </>
}