
import Header from "../Components/Header";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
export default function WhyAKS() {
        const { t } = useTranslation();
        useEffect(() => {
                AOS.init();
              }, [])
        return<>
           <Header  head={t("WALL_AND_CORNER_PROTECTION")}bg="service/IN_HP_Primo_SD_21012561_001.jpg" paragraph={t("WALL_AND_CORNER_PROTECTION_p")}/>
    
   
    </>
}


