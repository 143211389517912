import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

import $ from 'jquery';

export default function WhyVanle() {
   const { t } = useTranslation();
   useEffect(() => {
      AOS.init();
      var windowHeight = $(window).height();
      $('.div1').height(windowHeight);
    }, [])
    return<>
    <div className="whystaron ">
    <div class="div1">
    <div className="shadow">
        
    </div>
    <div className="social">
    <h1>{t("Why")} {t("Tarkett")}</h1>
        <p>{t("Tarkett_p")}</p>
    </div>
  </div>

    <div className=' service mr-100'>   

<div className="flip ">
   <h1><span>|</span> {t("Tarkett")} {t("category")}</h1>
</div>
<div className="flip " data-aos="fade-down-right" data-aos-duration="500">
<div class="front">

</div>
   <div class="back">
      <h2>ECLIPSE PREMIUM</h2>
      <p></p>
   </div>
   <a href="/ECLIPSE" className="queiklink">{t("read_more")}</a>

</div> 
<div className="flip " data-aos="fade-down-left" data-aos-duration="500">
<div class="front">

   </div>

   <div class="back">
      <h2>IQ TORO SC</h2>
      <p></p>
   </div>
   <a href="/iQToroSC" className="queiklink">{t("read_more")}</a>

</div> 
<div className="flip " data-aos="fade-up-right" data-aos-duration="500">
<div class="front">

   </div>
   <div class="back">
      <h2>STANDARD PLUS (2.0 MM)
</h2>
      <p></p>
   </div>
   <a href="/STANDARD" className="queiklink">{t("read_more")}</a>

</div> 
<div className="flip " data-aos="fade-up" data-aos-duration="500">
<div class="front">

   </div>
   <div class="back">
      <h2>PRIMO SD
</h2>
      <p></p>
   </div>
   <a href="/PRIMO" className="queiklink">{t("read_more")}</a>

</div> 
<div className="flip " data-aos="fade-up-left" data-aos-duration="500">
<div class="front">

   </div>
   <div class="back">
      <h2>RUBY 70 ACOUSTIC</h2>
      <p></p>
   </div>
   <a href="/RUBY" className="queiklink">{t("read_more")}</a>

</div> 
</div>
<div className="leftpatern"></div>
 <div className="rightpatern"></div>
 
    </div>

    </>
}