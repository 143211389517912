import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import $ from 'jquery';

export default function WhyHpl() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
    var windowHeight = $(window).height();
    $('.div1').height(windowHeight);
  }, [])
    return<>
    <div className="whystaron ">
    <div class="div1">
    <div className="shadow">
        
    </div>
    <div className="social">
    <h1>{t("Why")} {t("hpl")}</h1>
        <p>{t("hpl_p")}</p>
    </div>
  </div>
  <div className="brousore flex flex-2   mt-100">
    
    <div className="header ">
       <h1><span>|</span> ADVANTAGES OF THE HPL COMPACT</h1>
       <ul><li  data-aos="fade-up"
     data-aos-duration="500">Anti-bacterial</li>
       <li data-aos="fade-up"
     data-aos-duration="600">Easy to clean</li>
       <li data-aos="fade-up"
     data-aos-duration="700">Hygienic</li>
       <li data-aos="fade-up"
     data-aos-duration="800">Resistant to harmful chemicals</li>
       <li data-aos="fade-up"
     data-aos-duration="900">Resistance to water and fire</li>
       </ul>
    </div>
    <div className="item dimensions">
    <Tabs
      defaultActiveKey="applecation"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab data-aos="zoom-in"
     data-aos-duration="900" eventKey="applecation" title="applecation">
      <p>Toilet partitions<br/>Urinal partitions<br/>Lockers<br/>Tables<br/>Trash Boxes</p>
      </Tab>
      <Tab eventKey="dimensions" title="dimensions">
      <p>1.83 cm * 1.83 cm<br/>Thickness : 12 mm</p>
      </Tab>
    </Tabs>
    </div>
   
    </div>
<div className="brousore flex flex-3 mr-100">
    
<div className="header ">
   <h1><span>|</span> {t("our documents")}</h1>
</div>
<div className="item" data-aos="fade-up"
     data-aos-duration="500">
    <div className="bg" style={{backgroundImage: `url(${require("../Assets/prev.png")})`}}>

    </div>
    <h1>TECHNICAL DATA SHEET</h1>
    <a href={require("../Assets/uploads/2022/05/COMPACT-TECH-SPECS-CGS-8-TO-12MM-3.pdf")}>{t("download")}</a>
</div>
<div className="item" data-aos="fade-up"
     data-aos-duration="700">
    <div className="bg" style={{backgroundImage: `url(${require("../Assets/company.png")})`}}>

    </div>
    <h1>ID_INT_iQ_PREMIUM</h1>
    <a href="https://media.tarkett-image.com/docs/ID_INT_iQ_PREMIUM.pdf">{t("download")}</a>
</div>
</div>
    
    </div>
  
    </>
}