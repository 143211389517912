import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from "react-i18next";

export default function StaronColor(){

      const { t } = useTranslation();

    return<div className="documentation wid100"> 
  <Tabs
      defaultActiveKey="TEMPEST"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      {/* <Tab eventKey="all" title="all">
      <section className=" flex hpl mr-100">

            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>851XL – COLD WHITE</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/858XL-POLAR-WHITE.jpg")})`}} >
                <div className="shadow">
                <h4>858XL – POLAR WHITE</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/13XL-FROSTY-WHITEEGG-WHITE-1364x2048.jpg")})`}} >
                <div className="shadow">
                <h4>13XL – FROSTY WHITEEGG WHITE
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/855XL-IVORY-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>855XL – IVORY</h4>
                        </div>              
</div>
</section>
      </Tab>
      <Tab eventKey="Functional" title="Functional 6">
      <section className=" flex hpl mr-100">

            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>851XL – COLD WHITE</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/858XL-POLAR-WHITE.jpg")})`}} >
                <div className="shadow">
                <h4>858XL – POLAR WHITE</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/13XL-FROSTY-WHITEEGG-WHITE-1364x2048.jpg")})`}} >
                <div className="shadow">
                <h4>13XL – FROSTY WHITEEGG WHITE
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/855XL-IVORY-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>855XL – IVORY</h4>
                        </div>              
</div>
</section>
      </Tab>
      <Tab eventKey="Solid" title="Solid 18">
      <section className=" flex hpl mr-100">

            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>851XL – COLD WHITE</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/858XL-POLAR-WHITE.jpg")})`}} >
                <div className="shadow">
                <h4>858XL – POLAR WHITE</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/13XL-FROSTY-WHITEEGG-WHITE-1364x2048.jpg")})`}} >
                <div className="shadow">
                <h4>13XL – FROSTY WHITEEGG WHITE
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/855XL-IVORY-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>855XL – IVORY</h4>
                        </div>              
</div>
</section>
      </Tab>
      <Tab eventKey="Metalic" title="Metalic 3">
      <section className=" flex hpl mr-100">

            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>851XL – COLD WHITE</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/858XL-POLAR-WHITE.jpg")})`}} >
                <div className="shadow">
                <h4>858XL – POLAR WHITE</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/13XL-FROSTY-WHITEEGG-WHITE-1364x2048.jpg")})`}} >
                <div className="shadow">
                <h4>13XL – FROSTY WHITEEGG WHITE
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/855XL-IVORY-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>855XL – IVORY</h4>
                        </div>              
</div>
</section>
      </Tab>
      <Tab eventKey="SuperMe" title="SuperMe 19">
      <section className=" flex hpl mr-100">

            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>851XL – COLD WHITE</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/858XL-POLAR-WHITE.jpg")})`}} >
                <div className="shadow">
                <h4>858XL – POLAR WHITE</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/13XL-FROSTY-WHITEEGG-WHITE-1364x2048.jpg")})`}} >
                <div className="shadow">
                <h4>13XL – FROSTY WHITEEGG WHITE
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/855XL-IVORY-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>855XL – IVORY</h4>
                        </div>              
</div>
</section>
      </Tab>
      <Tab eventKey="PEBBLE" title="PEBBLE 10">
      <section className=" flex hpl mr-100">

            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>851XL – COLD WHITE</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/858XL-POLAR-WHITE.jpg")})`}} >
                <div className="shadow">
                <h4>858XL – POLAR WHITE</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/13XL-FROSTY-WHITEEGG-WHITE-1364x2048.jpg")})`}} >
                <div className="shadow">
                <h4>13XL – FROSTY WHITEEGG WHITE
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/855XL-IVORY-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>855XL – IVORY</h4>
                        </div>              
</div>
</section>
      </Tab>
      <Tab eventKey="ASPEN" title="ASPEN 11">
      <section className=" flex hpl mr-100">

            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>851XL – COLD WHITE</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/858XL-POLAR-WHITE.jpg")})`}} >
                <div className="shadow">
                <h4>858XL – POLAR WHITE</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/13XL-FROSTY-WHITEEGG-WHITE-1364x2048.jpg")})`}} >
                <div className="shadow">
                <h4>13XL – FROSTY WHITEEGG WHITE
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/855XL-IVORY-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>855XL – IVORY</h4>
                        </div>              
</div>
</section>
      </Tab> */}
      <Tab eventKey="TEMPEST" title="TEMPEST 17">
      <section className=" flex hpl mr-100">

            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FP100_image_1-1.jpg")})`}} >
                <div className="shadow">
                <h4>Peak FP100</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FC112_image_1-1.jpg")})`}} >
                <div className="shadow">
                <h4>Coconut FC112</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FM111_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Meteor FM111
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FM111_image_1-1.jpg")})`}} >
                <div className="shadow">
                <h4>Meteor FM111</h4>
                        </div>              
</div>


<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FH114_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Horizon FH114</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FS115_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Shell FS115</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FB173_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Bamboo FB173
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FG174_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Genesis FG174</h4>
                        </div>              
</div>


<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FG144_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Glimmer FG144</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FZ184_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Zenith FZ184</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FW145_image_1.png")})`}} >
                <div className="shadow">
                <h4>Whippoorwill FW145
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FA159_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Adamantine FA159</h4>
                        </div>              
</div>


<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FC158_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Coffee Bean FC158</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FT191_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Terrazzo Como FT191</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FC188_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Caviar FC188
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FI187_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>IGNEOUS FI187</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/FR148_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>RADIANCE(SHIMMER) FR148</h4>
                        </div>              
</div>
</section>
      </Tab>
      <Tab eventKey="SANDED" title="SANDED 17">
      <section className=" flex hpl mr-100">

            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SM421_image_1.jpeg")})`}} >
                <div className="shadow">
                <h4>Sanded Cream SM421</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SB412_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Sanded Birch SB412</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SD413_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Sanded Dover SD413
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/WP410_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Sanded White Pepper WP410</h4>
                        </div>              
</div>

<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SG420_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Sanded Grey SG420</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SS440_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Sanded Sahara SS440</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SG441_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Sanded Gold Dust SG441
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SV430_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Sanded Vermillion SV430</h4>
                        </div>              
</div>

<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SH428_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Sanded Heron SH428</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/ST482_image_1.png")})`}} >
                <div className="shadow">
                <h4>Sanded Tundra ST482</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SC475_image_1.png")})`}} >
                <div className="shadow">
                <h4>Sanded Clay SC475
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SC457_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Sanded Chestnut SC457</h4>
                        </div>              
</div>

<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/DN421_image_1.jpeg")})`}} >
                <div className="shadow">
                <h4>Sanded Dark Nebula DN421</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SO423_image_1.jpeg")})`}} >
                <div className="shadow">
                <h4>Sanded Onyx SO423</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SI462_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>SANDED ICEBLUE SI462</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/SM463_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>SANDED MINT SM463
</h4>
                        </div>              
</div>

</section>
      </Tab>
      <Tab eventKey="QUARRY" title="QUARRY 5">
      <section className=" flex hpl mr-100">

            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/QD212_image_1-500x500.jpg")})`}} >
                <div className="shadow">
                <h4>MOSAIC DALMATIAN QD212</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/QM228_image_1-300x300.jpg")})`}} >
                <div className="shadow">
                <h4>QUARRY MUSLIN QM228</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/QG229_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>QUARRY GREIGE QG229
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/QR278_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>QUARRY RIDGE QR278</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/QS288_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>QUARRY STARRED(N) QS288</h4>
                        </div>              
</div>
</section>
      </Tab>
      <Tab eventKey="TERRAZO" title="TERRAZO">
      <section className=" flex hpl mr-100">

            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/NT970_image_1-300x300.jpg")})`}} >
                <div className="shadow">
                <h4>TERRAZZO BOLOGNA NT970</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/NT150_image_1-300x300.jpg")})`}} >
                <div className="shadow">
                <h4>TERRAZZO VENEZIA NT150</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/03/NT981_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>TERRAZZO TORINO NT981
</h4>
                        </div>              
</div>

</section>
      </Tab>
      
    </Tabs>
    </div >
}
