import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
function TopNavbar() {
    const { t } = useTranslation();

    return ( <>
    

    <div className="TopNavbar appeare" >
    
   <div className='container'>
   <div className='information '>
    <a className='tag'><i class="fa-solid fa-phone ph"></i> +201090029220</a>
    <a className='tag'><i class="fa-regular fa-envelope enve"></i> info@straronegypt.com.eg</a>
    </div>
    <Link  to='/Contact' className='btn hvr-bounce-to-top'>{t("top_bar_Contact_Us")}</Link>

   </div>


    </div>
    
    
    
    
    
    
    </> );
}

export default TopNavbar;