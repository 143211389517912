import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer () {
    const { t } = useTranslation();

    return ( <>
    <footer className="Footer" >
    <div className="container ">
    <div className='row'>
    <div className='col-lg-3 col-md-4 col-xs-12 social' data-aos="fade-right">
    <img src={require("../Assets/logo/Untitled-2-02.png")}/>   
         <p>{t("prev")}</p>
        <h1>{t("social_With_us")}</h1>
        <a href="https://www.facebook.com/StaronEgypt" target="blank"><i className="fa-brands fa-facebook-f facebook"></i></a>
        <a href="http://wa.me/+201090029220"target="blank"><i class="fa-brands fa-whatsapp whatsapp"></i></a>
        <a href="https://www.instagram.com/staron_egypt/"target="blank"> <i className="fa-brands fa-instagram instagram"></i></a>

        <a href="https://eg.linkedin.com/company/staron-egypt"target="blank"> <i class="fa-brands fa-linkedin-in linkedin"></i></a>
    </div>
    <div className='col-lg-3 col-md-4 col-xs-12 catalog' data-aos="fade-up-left">
    <div className="container ">
        <div className="row ">
        <h1>{t("Navigetions")}</h1>
        <Link className="Home" to='/'>{t("nav_bar_home")}</Link>
       
        <Link className="Home" to='/Whystaron'>{t("staron")}</Link>
        <Link className="Home" to='/WhyRez'>{t("Radianz")}</Link>
        <Link className="Home" to='/WhyHpl'>{t("nav_bar_hpl")}</Link>
        <Link className="Home" to='/WhyVanle'>{t("nav_bar_VINYL")}</Link>
        <Link className="Home" to='/akscategory'>{t("nav_bar_pvc")}</Link>
       
        
        </div>
    </div>
    </div>
    <div className='col-lg-3 col-md-4 col-xs-12 catalog' data-aos="fade-up-left">
    <div className="container ">
        <div className="row ">
        <h1>{t("sister_company")}</h1>
    
        <a className="Home" to='/Portfolio'>XYZ design contractors</a>
        </div>
        <div className="row mt-50">
        <h1>{t("company")}</h1>
        <a className="Home" href='/'>darfadar</a>
       
        <a className="Home" href='https://kitchen-boutique.net/'>kitchen boutique</a>
       
        </div>
    </div>
    </div>
    <div className='col-lg-3 col-md-4 col-xs-12' data-aos="fade-up-left">
    <div className="container ">
        <div className="row catalog">
        <h1>references</h1>
        <a href = {require("../Assets/pdf/CP.pdf")} target = "_blank">{t("Company Profile")}</a>
        <a href = {require("../Assets/pdf/prevproject.pdf")} target = "_blank">{t("Previous Projects")}</a>
        <a href = {require("../Assets/pdf/Staron_Radianz_Catalogue_kr.pdf")} target = "_blank">{t('staron')} & {t("Radianz")} {t("catalogue")} </a>
        <a href = {require("../Assets/pdf/standard_grade.pdf")} target = "_blank">{t("crown")} {t("catalogue")} </a>
        <a href = {require("../Assets/pdf/AKS Profil Catalog.pdf")} target = "_blank">{t("AKS Profil")} {t("catalogue")} </a>
        <a href = {require("../Assets/pdf/Tarkett BR_INT_VINYL_ROLL.pdf")} target = "_blank">{t("Tarkett")} {t("catalogue")} </a>
       
        <h1 className="mt-50">For More Info.</h1>
        <Link className="Home" to='/Contact'>{t("top_bar_Contact_Us")}</Link>
       
        <Link className="Home" to='/Appointment'>{t("nav_bar_Appointment")}</Link>
        </div>
    </div>
    </div>

    </div>
    </div>
      
    <div className="copy">
        Staron Egypt © All Rights Reserved 2023


        </div> 
            </footer>
            
            
    </>
     );
}

export default Footer ;