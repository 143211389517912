import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import "bootstrap/dist/css/bootstrap.min.css";
import "flag-icon-css/css/flag-icons.min.css";
import Preloader from './Components/Preloader';

import "./i18next";
import App from './App';

const loadingmarkup=<Preloader/>
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback> 
     <React.StrictMode>
    <App />
  </React.StrictMode>
  </Suspense>
);

reportWebVitals();
