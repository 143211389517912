import { useState,useEffect } from "react";
import Header from "../Components/Header";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay ,Navigation } from 'swiper/modules';
import Fancybox from "../Components/Fancybox";
import { useParams } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function CategoryDetails(params) {
    const [cc1,Setcc1]=useState(1);
    const { id } = useParams();
    const [cc,Setcc]=useState(7);
    useEffect(()=>{
        AOS.init();

      if(window.innerWidth>900){
      Setcc(7);
    }
    else if(window.innerWidth>700){
      Setcc(5);
    }
    else if(window.innerWidth>600){
      Setcc(3);
    }
    else{
      Setcc(1);
    }
    },[])
    useEffect(()=>{
     
      window.addEventListener('resize', handleWindowResize);
    
      },[window.innerWidth])
      const handleWindowResize=()=>{
        if(window.innerWidth>900){
          Setcc(7);
        }
        else if(window.innerWidth>700){
          Setcc(5);
        }
        else if(window.innerWidth>600){
          Setcc(3);
        }
        else{
          Setcc(1);
        }
      }
    const [data,setdata]=useState([
        {
            id:1,
            name:"DK-142 Wall Protection Barrier",
            image:"dk-142-1-768x576.jpg",
            image2:"dk-142-2.jpg",
            details:"<ul><li>142 mm width.</li><li>80 mm depth from wall.</li><li>Lower construction continuosly (without paddy 2 mm) aliminum profile.</li><li>Impact-absorbing rubber gaskets are available on aliminum profiles.</li><li>The top cover is vinly acrylic material. (Scratch-proof, antibacterial, fireproof features.)</li><li>Profile lengths are 4 meters.</li><li>The wall fitting apart (the handle unit), which is vinyl acrylic material, are adapted with 50 cm intervals.</li><li>The holding feature provide opportunity of protect wall and patients get support.</li><li>Rails and handrails are assembled to the wall with different screws and pegs according to wall structure.</li><li>Profile between the end corner, using joint bracelet for; skill defects or expansion joints defects.</li></ul>"

        },
        {
            id:2,
            name:"DK.1500 WALL PROTECTION BARRIER",
            image:"dk-1500-1-768x576.jpg",
            image2:"dk-142-2.jpg",
            details:"<ul><li>152 mm width.</li><li>80 mm depth from wall.</li><li>Lower construction continuosly (without paddy 2 mm) aliminum profile.</li><li>Impact-absorbing rubber gaskets are available on aliminum profiles.</li><li>The top cover is vinly acrylic material. (Scratch-proof, antibacterial, fireproof features.)</li><li>Profile lengths are 4 meters.</li><li>The wall fitting apart (the handle unit), which is vinyl acrylic material, are adapted with 50 cm intervals.</li><li>The holding feature provide opportunity of protect wall and patients get support.</li><li>Rails and handrails are assembled to the wall with different screws and pegs according to wall structure.</li><li>Profile between the end corner, using joint bracelet for; skill defects or expansion joints defects.</li></ul>"

        },
        {
            id:3,
            name:"DD.152 WALL PROTECTION BARRIER HANDRAIL",
            image:"dd-152-1-768x576.jpg",
            image2:"dk-142-2.jpg",
            details:"<ul><li>152 mm width.</li><li>80 mm depth from wall.</li><li>Lower construction continuosly (without paddy 2 mm) aliminum profile.</li><li>Impact-absorbing rubber gaskets are available on aliminum profiles.</li><li>The top cover is vinly acrylic material. (Scratch-proof, antibacterial, fireproof features.)</li><li>Profile lengths are 4 meters.</li><li>The wall fitting apart (the handle unit), which is vinyl acrylic material, are adapted with 50 cm intervals.</li><li>The holding feature provide opportunity of protect wall and patients get support.</li><li>Rails and handrails are assembled to the wall with different screws and pegs according to wall structure.</li><li>Profile between the end corner, using joint bracelet for; skill defects or expansion joints defects.</li></ul>"

        },
        {
            id:4,
            name:"DK. 200 WALL PROTECTION BARRIER",
            image:"dk-200-1-768x576.jpg",
            image2:"dk-142-2.jpg",
            details:"<ul><li>200 mm width.</li><li>33 mm depth from wall.</li><li>Lower construction continuosly (without paddy 2 mm) aliminum profile.</li><li>Impact-absorbing rubber gaskets are available on aliminum profiles.</li><li>The top cover is vinly acrylic material. (Scratch-proof, antibacterial, fireproof features.)</li><li>Profile lengths are 4 meters.</li><li>It is adapted with screws 50-75 cm intervals according to wall structure.</li><li>Between wall to wall barriers there is flexible lath which prevent the growth of bacteria, adapt to wall.</li></ul>"

        },
        {
            id:5,
            name:"DK. 150 WALL PROTECTION BARRIER",
            image:"dk-150-1-768x576.jpg",
            image2:"dk-142-2.jpg",
            details:"<ul><li>150 mm width.</li><li>25 mm depth from wall.</li><li>Lower construction continuosly (without paddy 2 mm) aliminum profile.</li><li>Impact-absorbing rubber gaskets are available on aliminum profiles.</li><li>The top cover is vinly acrylic material. (Scratch-proof, antibacterial, fireproof features.)</li><li>Profile lengths are 4 meters.</li><li>It is adapted with screws 50-75 cm intervals according to wall structure.</li><li>Between wall to wall barriers there is flexible lath which prevent the growth of bacteria, adapt to wall.</li></ul>"

        },
        {
            id:6,
            name:"DK. 100 WALL PROTECTION BARRIER",
            image:"dk-100-1-500x375.jpg",
            image2:"dk-142-2.jpg",
            details:"<ul><li>100 mm width.</li><li>20 mm depth from wall.</li><li>Lower construction continuosly (without paddy 2 mm) aliminum profile.</li><li>Impact-absorbing rubber gaskets are available on aliminum profiles.</li><li>The top cover is vinly acrylic material. (Scratch-proof, antibacterial, fireproof features)</li><li>Profile lengths are 4 meters.</li><li>It is adapted with screws 50-75 cm intervals according to wall structure.</li><li>Between wall to wall barriers there is flexible lath which prevent the growth of bacteria, adapt to wall.</li></ul>"

        },
        {
            id:7,
            name:"DK. 50 WALL PROTECTION BARRIER",
            image:"dk-50-1-500x375.jpg",
            image2:"dk-142-2.jpg",
            details:"<ul><li>Home PageWall and Corner ProtectionWall Protection Without HandrailDK.50 Wall Protection Barrier</li><li>50 mm width</li><li>25 mm depth from wall.</li><li>Lower construction continuosly (without paddy 2 mm) aliminum profile.</li><li>Impact-absorbing rubber gaskets are available on aliminum profiles.</li><li>The top cover is vinly acrylic material. (Scratch-proof, antibacterial, fireproof features.)</li><li>Profile lengths are 4 meters.</li><li>It is adapted with screws 50-75 cm intervals according to wall structure.</li><li>Between wall to wall barriers there is flexible lath which prevent the growth of bacteria, adapt to wall.</li></ul>"

        },
        {
            id:8,
            name:"KK.45 CORNER GUARD",
            image:"kk-45-1-768x576.jpg",
            image2:"dk-142-2.jpg",
            details:"<ul><li>Corner protector profiles are used to protect column, wall corner and colour from harming of shopping cart at malls and strecher, wheelchair at hospitals.</li><li>Also it is used to protect the surfaces and colours from harming tables and chairs at hotels, restaurants, schools, waiting rooms etc.</li><li>Column and wall corners protection functions can be applied as the vertical length.</li><li>The corner protection system which is directly attached to corner. This product is 45×45 mm.</li><li>Profile lengths are 3 meters.</li></ul>"

        },
        {
            id:9,
            name:"KK.55 CORNER GUARD",
            image:"kk-55-1-500x375.jpg",
            image2:"dk-142-2.jpg",
            details:"<ul><li>Corner protector profiles are used to protect column, wall corner and colour from harming of shopping cart at malls and strecher, wheelchair at hospitals.</li><li>Also it is used to protect the surfaces and colours from harming tables and chairs at hotels, restaurants, schools, waiting rooms etc.</li><li>Column and wall corners protection functions can be applied as the vertical length.</li><li>There are two types; the first one is directly attached to the corner, the second one is gotten through aliminum reinforcement profile with hidden screw.</li><li>The plastic end cover is attached to ends.</li><li>Profile lengths are 3 meters.</li></ul>"

        },
        {
            id:10,
            name:"KK-76 Corner Guard",
            image:"kk-76-1-500x375.jpg",
            image2:"dk-142-2.jpg",
            details:"<ul><li>Corner protector profiles are used to protect column,wall corner and colour from harming of shopping cart at malls and strecher, wheelchair at hospitals.</li><li>Also it is used to protect the surfaces and colours from harming tables and chairs at hotels, restaurants, schools, waiting rooms etc.</li><li>Column and wall corners protection functions can be applied as the vertical length.</li><li>There are two types; the first one is directly attached to the corner, the second one is gotten through aliminum reinforcement profile with hidden screw.</li><li>The plastic end cover is attached to ends.</li><li>Profile lengths are 3 meters.</li></ul>"

        },
        {
            id:11,
            name:"KK-76-135 – Corner Guard",
            image:"kk-76-135-1-500x375.jpg",
            image2:"dk-142-2.jpg",
            details:"<ul><li>Corner protector profiles are used to protect column, wall corner and colour from harming of shopping cart at malls and strecher, wheelchair at hospitals.</li><li>Also it is used to protect the surfaces and colours from harming tables and chairs at hotels, restaurants, schools, waiting rooms etc.</li><li>Column and wall corners protection functions can be applied as the vertical length.</li><li>There are two types; the first one is directly attached to the corner, the second one is gotten through aliminum reinforcement profile with hidden screw.</li><li>The plastic end cover is attached to ends.</li><li>Profile lengths are 3 meters.</li></ul>"

        }
        
    ]);
    return<div className="pvccatalog">
    <Header head={data[id-1].name} bg="uploads/2022/04/IN_HP_Ruby_Acoustic_25148001_25098075_25099075_001-1920x1080.jpg"/>
    <div className="brousore flex flex-2   mr-100" >
    
    <div className="header ">
   <h1><span>|</span> category details</h1>
    <Fancybox
  options={{
    Carousel: {
      infinite: false,
    },
  }}
>
    
<Swiper
  slidesPerView={cc1}
  centeredSlides={true}
  pagination={{
    clickable: true,
    
  }}
  autoplay={{
      delay: 3000,
      disableOnInteraction: false,
    }}
    
  loop
  navigation={true}
  modules={[ Autoplay,Navigation ]}
  className="mySwiper-1"
><SwiperSlide>
  <a data-fancybox="gallery"data-caption="IN_HP_RUBY70" href={require("../Assets/uploads/2022/05/"+data[id-1].image)}>
    <img className="gallery" src={require("../Assets/uploads/2022/05/"+data[id-1].image)} width="200" height="150" />
  </a></SwiperSlide>
  <SwiperSlide>
  <a data-fancybox="gallery"data-caption="IN_HP_RUBY70" href={require("../Assets/uploads/2022/05/"+data[0].image2)}>
    <img className="gallery" src={require("../Assets/uploads/2022/05/"+data[id-1].image2)} width="200" height="150" />
  </a></SwiperSlide>
  </Swiper> 
</Fancybox>

    </div>
    <div className="item  dimensions"data-aos="fade-up-right" data-aos-duration="500" dangerouslySetInnerHTML={{ __html: data[id-1].details }}>
    
    </div>
   
    </div>
    <Swiper
  slidesPerView={cc}
  centeredSlides={true}
  pagination={{
    clickable: true,
    
  }}
  autoplay={{
      delay: 2000,
      disableOnInteraction: false,
    }}
    
  loop
  navigation={true}
  modules={[ Autoplay,Navigation ]}
  className="mySwiper-1 mr-100"
>

<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/8001.jpg")}/>
    <h5>8001</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/8002.jpg")}/>
    <h5>8002</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/8003.jpg")}/>
    <h5>8003</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/8004.jpg")}/>
    <h5>8004</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/8005.jpg")}/>
    <h5>8005</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/8006.jpg")}/>
    <h5>8006</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/8007.jpg")}/>
    <h5>8007</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/8008.jpg")}/>
    <h5>8008</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5002.jpg")}/>
    <h5>5002</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5002.jpg")}/>
    <h5>5003</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5004.jpg")}/>
    <h5>5004</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5005.jpg")}/>
    <h5>5005</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5006.jpg")}/>
    <h5>5006</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5007.jpg")}/>
    <h5>5007</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5008.jpg")}/>
    <h5>5008</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5009.jpg")}/>
    <h5>5009</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5010.jpg")}/>
    <h5>5010</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5011.jpg")}/>
    <h5>5011</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5012.jpg")}/>
    <h5>5012</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5013.jpg")}/>
    <h5>5013</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5014.jpg")}/>
    <h5>5014</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5015.jpg")}/>
    <h5>5015</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5016.jpg")}/>
    <h5>5016</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5017.jpg")}/>
    <h5>5017</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5018.jpg")}/>
    <h5>5018</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5019.jpg")}/>
    <h5>5019</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5020.jpg")}/>
    <h5>5020</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5021.jpg")}/>
    <h5>5021</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5022.jpg")}/>
    <h5>5022</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5023.jpg")}/>
    <h5>5023</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5024.jpg")}/>
    <h5>5024</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5025.jpg")}/>
    <h5>5025</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5026.jpg")}/>
    <h5>5026</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5001.jpg")}/>
    <h5>5001</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5050.jpg")}/>
    <h5>5050</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5051.jpg")}/>
    <h5>5051</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5052.jpg")}/>
    <h5>5052</h5>
</SwiperSlide>
<SwiperSlide>
    <img src={require("../Assets/uploads/2022/05/5053.jpg")}/>
    <h5>5053</h5>
</SwiperSlide>
</Swiper>

    </div>
}