import Header from "../Components/Header";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
export default function Redzcolor() {
        const { t } = useTranslation();
        useEffect(() => {
                AOS.init();
              }, [])
    return<>
       <Header  head="Radianz Colors" bg="service/IN_HP_Primo_SD_21012561_001.jpg" paragraph=""/>
        <section className=" flex hpl mr-100">

<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/DW105_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Diamond White DW105</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/MS141_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Mont Blanc Snow MS141</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/TG145_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Tigres TG145</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/WH110_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Whitehorn WH110</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/AW130_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Aleutian White AW130</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/EW120_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Everest White EW120</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/BI126_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Bering Ice BI126</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/NW124_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Nantucket Whale NW124</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/SH145_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>St. Helens White SH145</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/DG120_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Durango DG120</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/DC155_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Denali Cloud DC155</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/LO260_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Lotus LO260</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/SR135_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Sorrel SR135</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/LL195_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Lucern Lake LL195</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/AC711_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Acacia AC711</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/PC217_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Pyrenees Cream PC217</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/AT254_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Attica AT254</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/Sirius_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Sirius SIRIUS</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/GB210_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Gobi Beige GB210</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/CC720_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Campos Coal CC720</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/KC215_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Kalahari Dune KC215</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/VO890_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Vortex VO890</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/CG910_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Columbia Gray CG910</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/NB278_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Napoli Beige NB278</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/BJ212_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Bergamo Jet BJ212</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/SE850_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Segesta SE850</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/TH840_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Tehama TH840</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/UG950_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Ural Gray UG950</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/04/KI992_image_1.jpg")})`}} >
                <div className="shadow">
                <h4>Kunlun Ink KI992</h4>
                        </div>              
</div>
</section>
    </>
}