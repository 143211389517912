import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from "react-i18next";

export default function Staronpagedoc(){

      const { t } = useTranslation();

    return<div className="documentation"> 
  <Tabs
      defaultActiveKey="ProductOffering"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="ProductOffering" title={t("Product_Offering")}>
      <div  className="grid-container">
<div  className="wp-block-file"><a id="wp-block-file--media-8f29383d-099f-4f56-94a1-53589a38574e" href={require("../Assets/uploads/2022/03/SN-322-2022_Staron_adhesive_sharing_table_v.22-1-1.pdf")}>SN-322-2022 Staron adhesive sharing table v.22-1</a><a href={require("../Assets/uploads/2022/03/SN-322-2022_Staron_adhesive_sharing_table_v.22-1-1.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-8f29383d-099f-4f56-94a1-53589a38574e">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-c0da1e4e-ea8a-4b7c-a444-3c9daab757a2" href={require("../Assets/uploads/2022/03/SN-302-2020_Staron_Sinks___Bowls_Dimensions-1.pdf")}>SN-302-2020 Staron Sinks &amp; Bowls Dimensions</a><a href={require("../Assets/uploads/2022/03/SN-302-2020_Staron_Sinks___Bowls_Dimensions-1.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-c0da1e4e-ea8a-4b7c-a444-3c9daab757a2">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-ed92a936-6497-43e2-b7af-20d3c0437cf1" href={require("../Assets/uploads/2022/03/SN-301-2020_Staron_Prouct_Dimensions-2.pdf")}>SN-301-2020 Staron Product Dimensions</a><a href={require("../Assets/uploads/2022/03/SN-301-2020_Staron_Prouct_Dimensions-2.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-ed92a936-6497-43e2-b7af-20d3c0437cf1">Download</a></div>
</div>
      </Tab>
      <Tab eventKey="TechnicalBulletin" title={t("Technical_Bulletin")}>
      <div  className="grid-container">
<div  className="wp-block-file"><a id="wp-block-file--media-d5020df7-0dc3-420e-9393-9788dcf1e811" href={require("../Assets/uploads/2022/03/Staron-adhesive-user-manual.pdf")}>Staron adhesive user manual</a><a href={require("../Assets/uploads/2022/03/Staron-adhesive-user-manual.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-d5020df7-0dc3-420e-9393-9788dcf1e811">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-e9d950bb-816a-4bea-b195-8f1647407ea2" href={require("../Assets/uploads/2022/03/Specification-form-Solid-Surface-Fabrications-section-06-61-16.pdf")}>Specification form Solid Surface Fabrications </a><a href={require("../Assets/uploads/2022/03/Specification-form-Solid-Surface-Fabrications-section-06-61-16.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-e9d950bb-816a-4bea-b195-8f1647407ea2">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-3cecd2b6-224c-4a04-9465-1bb7ecd93bb7" href={require("../Assets/uploads/2022/03/LEED-green-building-rating-system.pdf")}>LEED green building rating system</a><a href={require("../Assets/uploads/2022/03/LEED-green-building-rating-system.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-3cecd2b6-224c-4a04-9465-1bb7ecd93bb7">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-6361ccc9-20c9-467f-976a-802688a598ba" href={require("../Assets/uploads/2022/03/Lavatories-and-ADA-Requirement.pdf")}>Lavatories and ADA Requirement</a><a href={require("../Assets/uploads/2022/03/Lavatories-and-ADA-Requirement.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-6361ccc9-20c9-467f-976a-802688a598ba">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-41b98118-fbfa-4e4b-b8a1-182a2e1dac63" href={require("../Assets/uploads/2022/03/Fabrication-Installation-of-Supreme.pdf")}>Fabrication Installation of Supreme</a><a href={require("../Assets/uploads/2022/03/Fabrication-Installation-of-Supreme.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-41b98118-fbfa-4e4b-b8a1-182a2e1dac63">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-2af8506b-fbdf-4106-b918-939baf7b8f3e" href={require("../Assets/uploads/2022/03/Elimination-of-HIV.pdf")}>Elimination of HIV</a><a href={require("../Assets/uploads/2022/03/Elimination-of-HIV.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-2af8506b-fbdf-4106-b918-939baf7b8f3e">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-5b12a439-96b9-4bf1-9296-0ebed179113a" href={require("../Assets/uploads/2022/03/Effect-of-Disinfectant.pdf")}>Effect of Disinfectant</a><a href={require("../Assets/uploads/2022/03/Effect-of-Disinfectant.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-5b12a439-96b9-4bf1-9296-0ebed179113a">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-f18447bf-c693-42e0-aeb8-bbc1fefb0ab6" href={require("../Assets/uploads/2022/03/Directional-pattern-of-Pearl-Particles.pdf")}>Directional pattern of Pearl Particles</a><a href={require("../Assets/uploads/2022/03/Directional-pattern-of-Pearl-Particles.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-f18447bf-c693-42e0-aeb8-bbc1fefb0ab6">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-7e593d46-49b6-46a5-8343-b9f2723ad108" href={require("../Assets/uploads/2022/03/Choosing-ideal-color-and-Acknowledgement.pdf")}>Choosing ideal color and Acknowledgement</a><a href={require("../Assets/uploads/2022/03/Choosing-ideal-color-and-Acknowledgement.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-7e593d46-49b6-46a5-8343-b9f2723ad108">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-71f3bd18-6453-45e7-b266-b970c098ce61" href={require("../Assets/uploads/2022/03/Characteristics-of-translucent-colors.pdf")}>Characteristics of translucent colors</a><a href={require("../Assets/uploads/2022/03/Characteristics-of-translucent-colors.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-71f3bd18-6453-45e7-b266-b970c098ce61">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-cf0cb17f-a044-463f-b92e-1062a760d868" href={require("../Assets/uploads/2022/03/Big-Chips-series.pdf")}>Big Chips series</a><a href={require("../Assets/uploads/2022/03/Big-Chips-series.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-cf0cb17f-a044-463f-b92e-1062a760d868">Download</a></div>
</div>
      </Tab>
      <Tab eventKey="FabricationManual" title={t("Fabrication_Manual")}>
      <div  className="grid-container">
<div  className="wp-block-file"><a id="wp-block-file--media-f8e8fdab-76ea-44bc-bee7-6e7883568652" href={require("../Assets/uploads/2022/03/SN-802-2020-Staron-Interior-wall-Installation.pdf")}>SN-802-2020 Staron Interior wall Installation</a><a href={require("../Assets/uploads/2022/03/SN-802-2020-Staron-Interior-wall-Installation.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-f8e8fdab-76ea-44bc-bee7-6e7883568652">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-7e2be472-712c-4fab-a08b-825a4f747d1d" href={require("../Assets/uploads/2022/03/SN-803-2020-Staron-Exterior-wall-cladding.pdf")}>SN-803-2020-Staron Exterior wall cladding</a><a href={require("../Assets/uploads/2022/03/SN-803-2020-Staron-Exterior-wall-cladding.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-7e2be472-712c-4fab-a08b-825a4f747d1d">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-ffac273d-1144-4cb8-9a07-dd719288f2f7" href={require("../Assets/uploads/2022/03/SN-804-2020-Staron-Fabrication-Manual.pdf")}>SN-804-2020-Staron Fabrication Manual</a><a href={require("../Assets/uploads/2022/03/SN-804-2020-Staron-Fabrication-Manual.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-ffac273d-1144-4cb8-9a07-dd719288f2f7">Download</a></div>
</div>
      </Tab>
      <Tab eventKey="Sustainability&Certification" title={t("Sustainability & Certification")}>
      <div  className="grid-container">
<div  className="wp-block-file"><a id="wp-block-file--media-ca01411b-3d0b-49f4-8327-5662ae680eab" href={require("../Assets/uploads/2022/03/HPD-Health-Product-declaration.pdf")}>HPD Health Product declaration</a><a href={require("../Assets/uploads/2022/03/HPD-Health-Product-declaration.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-ca01411b-3d0b-49f4-8327-5662ae680eab">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-9c7eaa7d-3463-4494-9983-a562417c7d7a" href={require("../Assets/uploads/2022/03/GREEN-GUARD.pdf")}>GREEN GUARD</a><a href={require("../Assets/uploads/2022/03/GREEN-GUARD.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-9c7eaa7d-3463-4494-9983-a562417c7d7a">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-cbec6932-3e56-4606-a8e4-aaa5ee853703" href={require("../Assets/uploads/2022/03/IAPMO-for-sink-and-bowl.pdf")}>IAPMO for sink and bowl</a><a href={require("../Assets/uploads/2022/03/IAPMO-for-sink-and-bowl.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-cbec6932-3e56-4606-a8e4-aaa5ee853703">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-6139e4a6-9be8-408f-a50e-580d2f6705f1" href={require("../Assets/uploads/2022/03/SIAA-for-Evermoin-Antimicrobial-1.pdf")}>SIAA for Evermoin (Antimicrobial)</a><a href={require("../Assets/uploads/2022/03/SIAA-for-Evermoin-Antimicrobial-1.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-6139e4a6-9be8-408f-a50e-580d2f6705f1">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-292510e2-0ff3-4634-a57e-754b6cc91028" href={require("../Assets/uploads/2022/03/SCS-recycled-product.pdf")}>SCS recycled product</a><a href={require("../Assets/uploads/2022/03/SCS-recycled-product.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-292510e2-0ff3-4634-a57e-754b6cc91028">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-df264911-dbbc-49c9-8606-a7d97d7b5278" href={require("../Assets/uploads/2022/03/ETA-European-Technical-Assessment-for-Exterior-cladding.pdf")}>ETA European Technical Assessment for Exterior cladding</a><a href={require("../Assets/uploads/2022/03/ETA-European-Technical-Assessment-for-Exterior-cladding.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-df264911-dbbc-49c9-8606-a7d97d7b5278">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-e227452e-327d-421d-b59f-8835a73dec9b" href={require("../Assets/uploads/2022/03/Management-System-ISO-9001-ISO-14001-ISO-50001.pdf")}>Management System (ISO 9001-ISO 14001-ISO 50001)</a><a href={require("../Assets/uploads/2022/03/Management-System-ISO-9001-ISO-14001-ISO-50001.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-e227452e-327d-421d-b59f-8835a73dec9b">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-4e55b981-3835-4a6e-9d29-a4277810bc97" href={require("../Assets/uploads/2022/03/EPD-Environmental-Product-declaration.pdf")}>EPD Environmental Product declaration</a><a href={require("../Assets/uploads/2022/03/EPD-Environmental-Product-declaration.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-4e55b981-3835-4a6e-9d29-a4277810bc97">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-9810b226-ce0c-4ea9-9f6c-ea7968117950" href={require("../Assets/uploads/2022/03/GREENGUARD-GOLD.pdf")}>GREENGUARD GOLD</a><a href={require("../Assets/uploads/2022/03/GREENGUARD-GOLD.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-9810b226-ce0c-4ea9-9f6c-ea7968117950">Download</a></div>



<div  className="wp-block-file"><a id="wp-block-file--media-0266bde9-e8ca-4068-8b9f-49cf486de0b0" href={require("../Assets/uploads/2022/03/NSF-ANSI-51.pdf")}>NSF ANSI 51</a><a href={require("../Assets/uploads/2022/03/NSF-ANSI-51.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-0266bde9-e8ca-4068-8b9f-49cf486de0b0">Download</a></div>
</div>
      </Tab>
      <Tab eventKey="Warranty&Care" title={t("Warranty&Care")}>
      <div  className="grid-container">
<div  className="wp-block-file"><a id="wp-block-file--media-885ad462-d655-44ce-bf49-6ee2a203be3a" href={require("../Assets/uploads/2022/03/Exterior-cladding-Warranty.pdf")}>Exterior cladding Warranty</a><a href={require("../Assets/uploads/2022/03/Exterior-cladding-Warranty.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-885ad462-d655-44ce-bf49-6ee2a203be3a">Download</a></div>
<div  className="wp-block-file"><a id="wp-block-file--media-0a7a7a0f-1631-499c-9785-c6337d0bffce" href={require("../Assets/uploads/2022/03/Commercial-Warranty.pdf")}>Commercial Warranty</a><a href={require("../Assets/uploads/2022/03/Commercial-Warranty.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-0a7a7a0f-1631-499c-9785-c6337d0bffce">Download</a></div>
<div  className="wp-block-file"><a id="wp-block-file--media-6a8f5f97-dcb6-4e80-bb59-c48324781d31" href={require("../Assets/uploads/2022/03/Residential-Warranty.pdf")}>Residential Warranty</a><a href={require("../Assets/uploads/2022/03/Residential-Warranty.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-6a8f5f97-dcb6-4e80-bb59-c48324781d31">Download</a></div>
<div  className="wp-block-file"><a id="wp-block-file--media-85906886-beaf-4803-ac24-ae9a0dba21ee" href={require("../Assets/uploads/2022/03/SN-313-2020-Care-and-Maintenance.pdf")}>SN-313-2020 Care and Maintenance</a><a href={require("../Assets/uploads/2022/03/SN-313-2020-Care-and-Maintenance.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-85906886-beaf-4803-ac24-ae9a0dba21ee">Download</a></div>
</div>
      </Tab>
      <Tab eventKey="MSDS" title={t("MSDS")}>
      <div  className="grid-container">
<div  className="wp-block-file"><a id="wp-block-file--media-0da5ce0a-c9bd-40eb-b31b-2dd1de01bec0" href={require("../Assets/uploads/2022/03/MSDS-Tempest.pdf")}>MSDS Tempest</a><a href={require("../Assets/uploads/2022/03/MSDS-Tempest.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-0da5ce0a-c9bd-40eb-b31b-2dd1de01bec0">Download</a></div>
<div  className="wp-block-file"><a id="wp-block-file--media-a488a192-23ca-431c-8d9f-aa9cbc15b15e" href={require("../Assets/uploads/2022/03/MSDS-Staron-adhesiveComponent-B.pdf")}>MSDS Staron adhesive (Component B)</a><a href={require("../Assets/uploads/2022/03/MSDS-Staron-adhesiveComponent-B.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-a488a192-23ca-431c-8d9f-aa9cbc15b15e">Download</a></div>
<div  className="wp-block-file"><a id="wp-block-file--media-4c3c2373-0c7d-461a-9599-bbcf2d08b87b" href={require("../Assets/uploads/2022/03/MSDS-Staron-and-Sinkbowl.pdf")}>MSDS Staron and Sinkbowl</a><a href={require("../Assets/uploads/2022/03/MSDS-Staron-and-Sinkbowl.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-4c3c2373-0c7d-461a-9599-bbcf2d08b87b">Download</a></div>
<div  className="wp-block-file"><a id="wp-block-file--media-9bfcbd2c-23f4-4815-8244-f403b6d16ea4" href={require("../Assets/uploads/2022/03/MSDS-Staron-adhesiveComponent-A.pdf")}>MSDS Staron adhesive (Component A)</a><a href={require("../Assets/uploads/2022/03/MSDS-Staron-adhesiveComponent-A.pdf")} className="wp-block-file__button" download="" aria-describedby="wp-block-file--media-9bfcbd2c-23f4-4815-8244-f403b6d16ea4">Download</a></div>
</div>
      </Tab>
    </Tabs>
    </div >
}
