
import Video from "./Vidios";
import Gallery from "./Gallery";

import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import StaronGallery from "./StaronGallery";
export default function Portofollio() {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
      }, [])
    return<>
    <div id="brousore" className="brousore flex flex-3 mr-200">
    
    <div className="header ">
       <h1><span>|</span> {t("Brochures")}</h1>
    </div>
    <div className="item" data-aos="fade-up"
         data-aos-anchor-placement="top-center"data-aos-duration="600">
        <div className="bg" style={{backgroundImage: `url(${require("../Assets/prev.png")})`}}>
    
        </div>
        <h1>{t("Previous Projects")}</h1>
        <a href={require("../Assets/pdf/prevproject.pdf")}>{t("download")}</a>
    </div>
    <div className="item"data-aos="fade-up"
         data-aos-anchor-placement="top-center"
         data-aos-duration="1000">
        <div className="bg" style={{backgroundImage: `url(${require("../Assets/company.png")})`}}>
    
        </div>
        <h1>{t("company profile")}</h1>
        <a href={require("../Assets/pdf/CP.pdf")}>{t("download")}</a>
    </div>
    </div>
    <StaronGallery/>

        <Video/>
    </>
}