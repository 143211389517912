import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom";
function Service() {
   const { t } = useTranslation();
   useEffect(() => {
      AOS.init();
    }, [])
    return (


<>
<div className=' service mt-100'>   

<div className="header">
   <h1><span>|</span> {t("our_Service")}</h1>
</div>
<div className="flip" data-aos="fade-up"
     data-aos-duration="1000">
<div className="front">

</div>
   <div className="back">
      <h2>{t("staron")}</h2>
      <p></p>
   </div>
   <Link to="/Whystaron" data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="300"
     data-aos-offset="0" className="queiklink">{t("read_more")}</Link>

</div> 
<div className="flip" data-aos="fade-up"
     data-aos-duration="1000">
<div className="front">

   </div>

   <div className="back">
      <h2>{t("Radianz")}</h2>
      <p></p>
   </div>
   <Link to="/WhyRez" data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="300"
     data-aos-offset="0" className="queiklink">{t("read_more")}</Link>

</div> 
<div className="flip" data-aos="fade-up"
     data-aos-duration="1000">
<div className="front">

   </div>
   <div className="back">
      <h2>{t("crown")}</h2>
      <p></p>
   </div>
   <Link to="/WhyHpl" data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="300"
     data-aos-offset="0" className="queiklink">{t("read_more")}</Link>

</div> 

</div>
<div className=' service c '> 
<div className="flip" data-aos="fade-up"
     data-aos-duration="1000">
<div className="front">

   </div>
   <div className="back">
      <h2>{t("Tarkett")}</h2>
      <p></p>
   </div>
   <Link to="/WhyVanle" data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="300"
     data-aos-offset="0" className="queiklink">{t("read_more")}</Link>
</div> 
<div className="flip" data-aos="fade-up"
     data-aos-duration="1000">
<div className="front">

   </div>
   <div className="back">
      <h2>AKS Profil</h2>
      <p></p>
   </div>
   <Link to="/akscategory" data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="300"
     data-aos-offset="0" className="queiklink">{t("read_more")}</Link>
</div>
</div></>


    );
}
   
export default Service;