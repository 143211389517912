import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Languagecheack from './Languagecheack';
import $ from 'jquery';

function NavBar() {
    const { t } = useTranslation();
  const [expand,Setwid]=useState("xl");
  const [expandnav,Setwidnav]=useState(true);
  const [activetap,Setactivetap]=useState(true);
useEffect(()=>{
  var $window = $(window);

window.onload = (event) => {
  $(".preloader").css("height","100%");
  console.log("loading");
  setTimeout(()=>{
    $(".preloader").css("height","0px");

  },1000)
};

  $window.on('scroll', function () {
    if ($window.scrollTop() > 0) {
        $('.head').addClass('sticky');
    } else {
        $('.head ').removeClass('sticky');
    }
});
 
if(window.innerWidth>=1200){
  Setwid('xl');
}
else if(window.innerWidth>992){
  Setwid("lg");
}
else if(window.innerWidth>768 ){
  Setwid("md");
}
else if(window.innerWidth>576 ){
  Setwid("sm");
}
else{
  Setwid("xs");
}
if(window.innerWidth>950){
  Setwidnav(true);
  $('.megamenu').addClass("none");

  $('.megamenu').mouseleave(function(){
    $(this).addClass("none");
});

$('#solid').hover(function(){
    $('.megamenu').addClass("none");
    $('.soliddiv').removeClass("none");
});

$('#hbl').hover(function(){
    $('.megamenu').addClass("none");
    $('.hbldiv').removeClass("none");

})
$('#VINYL').hover(function(){
    $('.megamenu').addClass("none");
    $('.VINYLdiv').removeClass("none");

});
$('#pvc').hover(function(){
    $('.megamenu').addClass("none");
    $('.pvcdiv').removeClass("none");

});
}
else{

  Setwidnav(false);
}
$(".a").on('click',function(){
  window.scrollTo({
      top:0,
      behavior: 'smooth'
  });
  console.log("run");
    if (!$(".navbar-toggler").hasClass("collapsed")) {
      // Collapse the navbar
      
      $(".navbar-toggler").click();
  }
  

});
}, [])

  return (
    <>
  
        <Navbar id='navbar' key={expand} expand={expand} className="navbar head" 
       >
          <Container fluid>
            <Navbar.Brand href="/">                  <img src={require("../Assets/logo/Untitled-2-01.png")}/></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
             
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img src={require("../Assets/logo/Untitled-2-01.png")}/>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
           {
            expandnav? <Nav className="justify-content-center" activeKey="/">
            <Link className="nav-link" to="/">{t("nav_bar_home")}</Link>
            <Link  className="nav-link"  id='solid' >{t("nav_bar_solid_surface")}<i class="fa-solid fa-chevron-down"></i></Link>
            <Link className="nav-link" id='hbl' >{t("nav_bar_hpl")}<i class="fa-solid fa-chevron-down"></i></Link>
            <Link className="nav-link" id='VINYL'>{t("nav_bar_VINYL")}<i class="fa-solid fa-chevron-down"></i></Link>
            <Link className="nav-link" id='pvc'>{t("nav_bar_pvc")}<i class="fa-solid fa-chevron-down"></i></Link>
                
                  <Link className="nav-link" to="/Appointment">{t("nav_bar_Appointment")}</Link>
                  <Link className="nav-link" to="/Portfolio">{t("Brochures")}</Link>
                  <Link ><Languagecheack/></Link>
                  <div className='megamenu  pvcdiv'>
        
        <div class="tabs flex flex-2" >
    <ul id="tabs-nav">
      <li><a href="#tab1">AKS Profil</a></li>
  
    </ul> 
    <div id="tabs-content">
  
  <div id="tab1" class="tab-content ">
  <div className='row'>
        <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
              about AKS Profil       
          </h1>
            <div className='links'>
              <Link to="/WhyAKS">{t("Why")} AKS Profil</Link>
              <Link to="/videoandgallery">{t("galary")}</Link>
              <a href = {require("../Assets/pdf/AKS Profil Catalog.pdf")} target = "_blank">AKS {t("E-catalogue")} </a>
              <Link to="/akscategory">{t("category")}</Link>
            </div>
          </div>
         
          </div>
      </div>
     
   
  
    </div> 
  </div> 
        
                      </div>
                    <div className='megamenu  VINYLdiv'  >
                    <div class="tabs flex flex-2">
              <ul id="tabs-nav">
                <li><a href="#tab1">{t("Tarkett")}</a></li>

              </ul> 
              <div id="tabs-content">

            <div id="tab1" class="tab-content ">
            <div className='row'>
                  <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
                        {t("nav_about_Tarkett")}     
                    </h1>
                      <div className='links'>
                        <Link to="/WhyVanle">{t("Why")} {t("Tarkett")}</Link>
                        <Link to="/videoandgallery">{t("galary")}</Link>
                        <a href = {require("../Assets/pdf/Tarkett BR_INT_VINYL_ROLL.pdf")} target = "_blank">{t("Tarkett")} {t("E-catalogue")} </a>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-8 col-xs-12 '> <h1>
                    {t("Tarkett")} {t("category")}      
                    </h1>
                      <div className='links'>
                      <Link to="/ECLIPSE" >ECLIPSE PREMIUM</Link>

                      <Link to="/iQToroSC" >IQ TORO SC</Link>

                      <Link to="/STANDARD" >STANDARD PLUS (2.0 MM)</Link>

                      <Link to="/PRIMO" >PRIMO SD</Link>

                      <Link to="/RUBY" >RUBY 70 ACOUSTIC</Link>

                      </div>
                    </div>
                      
                    </div>
                </div>
              
            

              </div> 
            </div>
                  </div>
                    <div className='megamenu  hbldiv'>
                    <div class="tabs flex flex-2">
              <ul id="tabs-nav">
                <li><a href="#tab1">{t("crown")}</a></li>
              

              </ul> 
              <div id="tabs-content">

            <div id="tab1" class="tab-content ">
            <div className='row'>
                  <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
                  {t("nav_about_hpl")}       
                    </h1>
                      <div className='links'>
                        <Link to="/WhyHpl">{t("Why")} {t("crown")}</Link>
                        <Link to="/videoandgallery/">{t("galary")}</Link>
                        <a href = {require("../Assets/pdf/standard_grade.pdf")} target = "_blank">{t("crown")} {t("E-catalogue")} </a>

                      </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
                    {t("crown")}      
                    </h1>
                      <div className='links'>
                        <Link to="/Hpl">{t("all_colors")}</Link>
                      
                      </div>
                      
                    </div>
                  
                      
                    </div>
                </div>
              
            

              </div> 
            </div>
                    </div>
                    <div className='megamenu  soliddiv'>
                    <div class="tabs flex flex-2">
            <ul id="tabs-nav">
            <li onClick={()=>{
              Setactivetap(true);
              
            }}><a >{t("staron")}</a></li>
            <li onClick={()=>{
              Setactivetap(false);
              
            }}><a >{t("Radianz")}</a></li>

            </ul> 
            <div id="tabs-content">
            {activetap?<div id="tab1" class="tab-content ">
            <div className='row'>
            <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
            {t("nav_about_staron")}       
              </h1>
                <div className='links'>
                  <Link to="/Whystaron">{t("Why")} {t("staron")}</Link>
                  <Link to="/StaronGalleryPage">{t("galary")}</Link>
                
                </div>
              </div>
              <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
                        {t("colors")}
              </h1>
                <div className='links'>
                  <Link to="/StaronColor"> {t("all_colors")}</Link>
                  <a href={require("../Assets/pdf/Staron_Radianz_Catalogue_kr.pdf")} target = "_blank" >{t("E-catalogue")}</a>
                </div>
              </div>
              <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
                  {t("technical_information")}       
              </h1>
                <div className='links'>
                  <Link to="/StaronDocumentation">{t("Documentation")}</Link>
                  <Link to="/StaronEdgeOption">{t("Edge_Options")}</Link>
                  <Link to="/staronproductDimensions">{t("product_Dimensions")}</Link>
                </div>
              </div>
                
              </div>
            </div>:<div id="tab2"class="tab-content " >
            <div className='row'>
            <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
                  {t("nav_about_RADIANZ")}        
              </h1>
                <div className='links'>
                  <Link to="/WhyRez">{t("Why")} {t("Radianz")}</Link>
                  <Link to="/videoandgallery">{t("galary")}</Link>
                
                </div>
              </div>
              <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
              {t("colors")}

              </h1>
                <div className='links'>
                  <Link to="/rezcolor">{t("all_colors")}</Link>
                  <a href={require("../Assets/pdf/Staron_Radianz_Catalogue_kr.pdf")} target = "_blank" >{t("E-catalogue")}</a>

                </div>
              </div>
              <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
              {t("technical_information")}       
              </h1>
                <div className='links'>
                  <Link to="ReadianzDocumentation">{t("Documentation")}</Link>
                  <Link to="ReadianzEdgeOption">{t("Edge_Options")}</Link>
                    <Link to="ReadianzProudctDimensions">{t("product_Dimensions")}</Link>
                </div>
              </div>
                
              </div>
            </div>}



            </div> 
            </div> 
                    </div>
                </Nav>:<Nav className="justify-content-center" activeKey="/">

                <Accordion defaultActiveKey="10">
                <Link  className="nav-link" to="/">{t("nav_bar_home")}</Link>

      <Accordion.Item className="nav-link" eventKey="0">
        <Accordion.Header>{t("nav_bar_solid_surface")}</Accordion.Header>
        <Accordion.Body>
        <div className='megamenu  soliddiv'>
        <div class="tabs flex flex-2">
<ul id="tabs-nav">
<li onClick={()=>{
  Setactivetap(true);
  
}}><a >{t("staron")}</a></li>
<li onClick={()=>{
  Setactivetap(false);
  
}}><a >{t("Radianz")}</a></li>

</ul> 
<div id="tabs-content">
{activetap?<div id="tab1" class="tab-content ">
<div className='row'>
<div className='col-lg-3 col-md-4 col-xs-12'> <h1>
{t("nav_about_staron")}       
  </h1>
    <div className='links'>
      <Link  to="/Whystaron">{t("Why")} {t("staron")}</Link>
      <Link  to="/StaronGalleryPage">{t("galary")}</Link>
     
    </div>
  </div>
  <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
            {t("colors")}
  </h1>
    <div className='links'>
      <Link  to="/StaronColor"> {t("all_colors")}</Link>
      <a href={require("../Assets/pdf/Staron_Radianz_Catalogue_kr.pdf")} target = "_blank" >{t("E-catalogue")}</a>
    </div>
  </div>
  <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
      {t("technical_information")}       
  </h1>
    <div className='links'>
      <Link  to="/StaronDocumentation">{t("Documentation")}</Link>
      <Link  to="/StaronEdgeOption">{t("Edge_Options")}</Link>
      <Link  to="/staronproductDimensions">{t("product_Dimensions")}</Link>
    </div>
  </div>
    
  </div>
</div>:<div id="tab2"class="tab-content " >
<div className='row'>
<div className='col-lg-3 col-md-4 col-xs-12'> <h1>
      {t("nav_about_RADIANZ")}        
  </h1>
    <div className='links'>
      <Link  to="/WhyRez">{t("Why")} {t("Radianz")}</Link>
      <Link  to="/videoandgallery">{t("galary")}</Link>
     
    </div>
  </div>
  <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
  {t("colors")}

  </h1>
    <div className='links'>
      <Link  to="/rezcolor">{t("all_colors")}</Link>
      <a href={require("../Assets/pdf/Staron_Radianz_Catalogue_kr.pdf")} target = "_blank" >{t("E-catalogue")}</a>

    </div>
  </div>
  <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
  {t("technical_information")}       
  </h1>
    <div className='links'>
      <Link  to="ReadianzDocumentation">{t("Documentation")}</Link>
      <Link  to="ReadianzEdgeOption">{t("Edge_Options")}</Link>
        <Link  to="ReadianzProudctDimensions">{t("product_Dimensions")}</Link>
    </div>
  </div>
    
  </div>
</div>}



</div> 
</div> 
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="nav-link" eventKey="1">
        <Accordion.Header>{t("nav_bar_hpl")}</Accordion.Header>
        <Accordion.Body>
        <div className='megamenu  hbldiv'>
        <div class="tabs flex flex-2">
  <ul id="tabs-nav">
    <li><a href="#tab1">{t("crown")}</a></li>
   

  </ul> 
  <div id="tabs-content">

<div id="tab1" class="tab-content ">
<div className='row'>
      <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
      {t("nav_about_hpl")}       
        </h1>
          <div className='links'>
            <Link  to="/WhyHpl">{t("Why")} {t("crown")}</Link>
            <Link  to="/videoandgallery/">{t("galary")}</Link>
            <a href = {require("../Assets/pdf/standard_grade.pdf")} target = "_blank">{t("crown")} {t("E-catalogue")} </a>

          
          </div>
        </div>
        <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
        {t("crown")}      
        </h1>
          <div className='links'>
            <Link  to="/Hpl">{t("all_colors")}</Link>
          
          </div>
          
        </div>
       
          
        </div>
    </div>
   
 

  </div> 
</div>
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="nav-link" eventKey="2">
        <Accordion.Header>{t("nav_bar_VINYL")}</Accordion.Header>
        <Accordion.Body>
        <div className='megamenu  VINYLdiv'  >
        <div class="tabs flex flex-2">
  <ul id="tabs-nav">
    <li><a href="#tab1">{t("Tarkett")}</a></li>

  </ul> 
  <div id="tabs-content">

<div id="tab1" class="tab-content ">
<div className='row'>
      <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
            {t("nav_about_Tarkett")}     
        </h1>
          <div className='links'>
            <Link  to="/WhyVanle">{t("Why")} {t("Tarkett")}</Link>
            <Link  to="/videoandgallery">{t("galary")}</Link>
            <a href = {require("../Assets/pdf/Tarkett BR_INT_VINYL_ROLL.pdf")} target = "_blank">{t("Tarkett")} {t("E-catalogue")} </a>
          </div>
        </div>
        <div className='col-lg-6 col-md-8 col-xs-12 '> <h1>
        {t("Tarkett")} {t("category")}      
        </h1>
          <div className='links'>
           <Link  to="/ECLIPSE" >ECLIPSE PREMIUM</Link>

           <Link  to="/iQToroSC" >IQ TORO SC</Link>

           <Link  to="/STANDARD" >STANDARD PLUS (2.0 MM)</Link>

           <Link  to="/PRIMO" >PRIMO SD</Link>

           <Link  to="/RUBY" >RUBY 70 ACOUSTIC</Link>

          </div>
        </div>
          
        </div>
    </div>
   
 

  </div> 
</div>
      </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="nav-link" eventKey="3">
        <Accordion.Header>{t("nav_bar_pvc")}</Accordion.Header>
        <Accordion.Body>
        <div className='megamenu  pvcdiv'>
        
        <div class="tabs flex flex-2" >
    <ul id="tabs-nav">
      <li><a href="#tab1">AKS Profil</a></li>
  
    </ul> 
    <div id="tabs-content">
  
  <div id="tab1" class="tab-content ">
  <div className='row'>
        <div className='col-lg-3 col-md-4 col-xs-12'> <h1>
              about AKS Profil       
          </h1>
            <div className='links'>
              <Link  to="/WhyAKS">{t("Why")} AKS Profil</Link>
              <Link  to="/videoandgallery">{t("galary")}</Link>
              <a href = {require("../Assets/pdf/AKS Profil Catalog.pdf")} target = "_blank">AKS {t("E-catalogue")} </a>
              <Link  to="/akscategory">{t("category")}</Link>
            </div>
          </div>
         
          </div>
      </div>
     
   
  
    </div> 
  </div> 
        
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Link  className="nav-link" to="/Appointment">{t("nav_bar_Appointment")}</Link>
                  <Link  className="nav-link" to="/Portfolio">{t("Brochures")}</Link>
                  <Link ><Languagecheack/></Link>
                  
    </Accordion>
                  {/* <Link className="nav-link" to="/">{t("nav_bar_home")}</Link>
                  <Link  className="nav-link"  id='solid' >{t("nav_bar_solid_surface")}<i class="fa-solid fa-chevron-down"></i></Link>
            <Link className="nav-link" id='hbl' >{t("nav_bar_hpl")}<i class="fa-solid fa-chevron-down"></i></Link>
            <Link className="nav-link" id='VINYL'>{t("nav_bar_VINYL")}<i class="fa-solid fa-chevron-down"></i></Link>
            <Link className="nav-link" id='pvc'>{t("nav_bar_pvc")}<i class="fa-solid fa-chevron-down"></i></Link>
                
                  <Link className="nav-link" to="/Appointment">{t("nav_bar_Appointment")}</Link>
                  <Link className="nav-link" to="/Portfolio">{t("Brochures")}</Link>
                  <Link ><Languagecheack/></Link> */}
                  
                </Nav>
              
           }
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        
    </>
  );
}

export default NavBar;