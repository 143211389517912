
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Autoplay ,Navigation } from 'swiper/modules';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function OurBrand() {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, [])
  const [cc,Setcc]=useState(4);
useEffect(()=>{
  if(window.innerWidth>900){
  Setcc(4);
}
else if(window.innerWidth>700){
  Setcc(3);
}
else if(window.innerWidth>600){
  Setcc(2);
}
else{
  Setcc(1);
}
},[])
useEffect(()=>{
 
  window.addEventListener('resize', handleWindowResize);

  },[window.innerWidth])
  const handleWindowResize=()=>{
    if(window.innerWidth>1100){
      Setcc(5);
    }
    else if(window.innerWidth>900){
      Setcc(4);
    }
    else if(window.innerWidth>600){
      Setcc(3);
    }
    else{
      Setcc(1);
    }
  }
  return (
   <>
    <div className='OurBrand' data-aos="fade-up">


<Swiper 
  slidesPerView={cc}
  centeredSlides={true}
  pagination={{
    clickable: true,
    
  }}
  autoplay={{
      delay: 1000,
      disableOnInteraction: false,
    }}
    
  loop
  navigation={false}
  modules={[ Autoplay,Navigation ]}
  className="mySwiper-1"
>


<SwiperSlide><img src={require("../../Assets/brand/inoxfera.png")}/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/mkdmmk.png")}/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/pomdor.png")}/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/serdanelli.png")}/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/faraana.png")}/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/shg.png")}/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/poggenpohl.png")}/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/bosch.png")}/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/fagor.png")}/></SwiperSlide>      
<SwiperSlide><img src={require("../../Assets/brand/giellesse.png")}/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/kohler.png")}/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/AKSProfil.png")} className='img'/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/Crown.png")} className='img'/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/Lotte.png")} className='img'/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/Radianz.png")} className='img'/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/staron.png")} className='img'/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/tarkett.png")} className='img'/></SwiperSlide>
<SwiperSlide><img src={require("../../Assets/brand/cristal-bronze.png")} /></SwiperSlide>

</Swiper>
<div className="header">

<h1><span>|</span> {t("partners")}</h1>
</div>
</div>
<div className='counter'>
  <div className='shadow'>
    <h1>{t("WELCOME_TO_STARON_EGYPT")} </h1>
    <p>{t("welcome_to_staron_p")}
</p>
  </div>
</div>
<div className='container'>
<div className="header">
    <h1><span>|</span>{t("certification")}</h1>
 </div>
<div className='cer' data-aos="flip-left">
  </div> 
</div>
  </>
  );
}