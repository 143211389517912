import Header from "../Components/Header";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
export default function Hpl() {
        const { t } = useTranslation();
        useEffect(() => {
                AOS.init();
              }, [])
    return<>
       <Header  head={t("crown")} bg="service/IN_HP_Primo_SD_21012561_001.jpg" paragraph={t("crown_p")}/>
        <section className=" flex hpl mr-100">

            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/851XL-COLD-WHITE-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>851XL – COLD WHITE</h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/858XL-POLAR-WHITE.jpg")})`}} >
                <div className="shadow">
                <h4>858XL – POLAR WHITE</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/13XL-FROSTY-WHITEEGG-WHITE-1364x2048.jpg")})`}} >
                <div className="shadow">
                <h4>13XL – FROSTY WHITEEGG WHITE
</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/855XL-IVORY-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>855XL – IVORY</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/859XL-PEANUT-1173x1536.jpg")})`}} >
                <div className="shadow">
                <h4>859XL – PEANUT</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/879-XL-BRUN-MOYEN-500x707.jpg")})`}} >
                <div className="shadow">
                <h4>879 XL – BRUN MOYEN</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/860XL602XL-SILVER-GREY-scaled.jpg")})`}} >
                <div className="shadow">
                <h4>860XL602XL – SILVER GREY</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/885XL-GREY-1024x1536.jpg")})`}} >
                <div className="shadow">
                <h4>885XL – GREY</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/861XL-GOTHIC-GREY-500x750.jpg")})`}} >
                <div className="shadow">
                <h4>861XL – GOTHIC GREY</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/601XL-NEW-ARTIC-WHITE-1366x2048.jpg")})`}} >
                <div className="shadow">
                <h4>601XL – NEW ARTIC WHITE</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/853XL-ROYALE-BLUE-500x776.jpg")})`}} >
                <div className="shadow">
                <h4>853XL – ROYALE BLUE</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/865XL-PARROT-GREEN-1295x2048.jpg")})`}} >
                <div className="shadow">
                <h4>865XL – PARROT GREEN</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/15XL-YELLOW-1023x1536.jpg")})`}} >
                <div className="shadow">
                <h4>15XL – YELLOW</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/864XL-MANGO-972x1536.jpg")})`}} >
                <div className="shadow">
                <h4>864XL – MANGO</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/857XL-ORANGE-1173x1536.jpg")})`}} >
                <div className="shadow">
                <h4>857XL – ORANGE</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/19XL-RED-1364x2048.jpg")})`}} >
                <div className="shadow">
                <h4>19XL – RED</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/916XL-ALBERTA-MAPLE-768x1536.jpg")})`}} >
                <div className="shadow">
                <h4>916XL – ALBERTA MAPLE</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/915XL-MANGFALL-BEECH-768x1536.jpg")})`}} >
                <div className="shadow">
                <h4>915XL – MANGFALL BEECH</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="700" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/922XL-SCOTIA-OAK-800x1600.jpg")})`}} >
                <div className="shadow">
                <h4>922XL – SCOTIA OAK</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/904XL-LORRAINE-CHERRY-500x1000.jpg")})`}} >
                <div className="shadow">
                <h4>904XL – LORRAINE CHERRY</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/905XL-NOCE-VERSAILLIES-1024x2048.jpg")})`}} >
                <div className="shadow">
                <h4>905XL – NOCE VERSAILLIES</h4>
                        </div>              
</div>
<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/uploads/2022/05/896XL-IPU-MAHOGANY-500x1000.jpg")})`}} >
                <div className="shadow">
                <h4>896XL – IPU MAHOGANY</h4>
                        </div>              
</div>
</section>
    </>
}