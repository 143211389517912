import 'bootstrap/dist/css/bootstrap.min.css';
import './Sass/main.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TopNavbar from './Components/TopNavbar';
import NavBar from './Components/NavBar';
import HomePage from './Pages/HomePage';
import Footer from './Components/Footer';
import Appointment from './Pages/Appointment';
import Contact from './Pages/Contact';
import Preloader from './Components/Preloader';
import Staronpagedoc from './Pages/Staronpagedoc';
import EadgeOption from './Pages/EadgeOption';
import ProductDimensions from './Pages/productDimensions';
import RadProductDimensions from './Pages/RadProductDimensions';
import RadEadgeOption from './Pages/RadEadgeOption';
import ReadianzDocumentation from './Pages/ReadianzDocumentation';
import AksCategory from './Pages/AksCategory';
import CategoryDetails from './Pages/CategoryDetails';
import IQToroSC from './Pages/iQToroSC';
import ECLIPSE from './Pages/ECLIPSE';
import STANDARD from './Pages/STANDARD';
import PRIMO from './Pages/PRIMO';
import RUBY from './Pages/RUBY';
import Whystaron from './Pages/Whystaron';
import WhyRez from './Pages/WhyRez';
import Hpl from './Pages/Hpl';
import WhyHpl from './Pages/WhyHpl';
import WhyVanle from './Pages/Whyvanle';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import StaronColor from './Pages/StaronColor';
import Portofollio from './Pages/Portofollio';
import GalleryPage from './Pages/GalleryPage';
import StaronGalleryPage from './Pages/StaronGalleryPage';
import WhyAKS from './Pages/WhyAKS';
import Redzcolor from './Pages/Redzcolor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function App() {
  const [screenLoading, setScreenLoading] = useState(true);
  useEffect(() => {
    setScreenLoading(true);

    setTimeout(() => {
      setScreenLoading(false);
    }, 2000);
    AOS.init();
  }, [])
  return (
  <>  {
      screenLoading ? <Preloader/>:<BrowserRouter>
  
      <TopNavbar/>
   <div className='fixid'><a href="http://wa.me/+201090029220"target="blank"><FontAwesomeIcon icon="fa-brands fa-whatsapp" /></a></div>
     <NavBar/>
     <Routes>
      <Route path='/' element={<HomePage/>}/>
      {/* <Route path='/Aboutus'element={<Aboutus/>}/> */}
      <Route path='/Contact'element={<Contact/>}/>
      <Route path='/StaronDocumentation'element={<Staronpagedoc/>}/>
      <Route path='/StaronEdgeOption'element={<EadgeOption/>}/>
      <Route path='/StaronColor'element={<StaronColor/>}/>
      <Route path='/rezcolor'element={<Redzcolor/>}/>
      <Route path='/staronproductDimensions'element={<ProductDimensions/>}/>
      <Route path='/ReadianzProudctDimensions'element={<RadProductDimensions/>}/>
      <Route path='/ReadianzEdgeOption'element={<RadEadgeOption/>}/>
      <Route path='/ReadianzDocumentation'element={<ReadianzDocumentation/>}/>
      <Route path='/Appointment'element={<Appointment/>}/>
      <Route path='/akscategory'element={<AksCategory/>}/>
      <Route path='/ECLIPSE'element={<ECLIPSE/>}/>
      <Route path='/STANDARD'element={<STANDARD/>}/>
      <Route path='/PRIMO'element={<PRIMO/>}/>
      <Route path='/RUBY'element={<RUBY/>}/>
      <Route path='/Whystaron'element={<Whystaron/>}/>
      <Route path='/WhyRez'element={<WhyRez/>}/>
      <Route path='/iQToroSC'element={<IQToroSC/>}/>
      <Route path='/Hpl'element={<Hpl/>}/>
      <Route path='/WhyHpl'element={<WhyHpl/>}/>
      <Route path='/WhyVanle'element={<WhyVanle/>}/>
      <Route path='/WhyAKS'element={<WhyAKS/>}/>
      <Route path='/akscategory/:id'element={<CategoryDetails/>}/>
      <Route path='/Portfolio'element={<Portofollio/>}/>
      <Route path='/StaronGalleryPage'element={<StaronGalleryPage/>}/>
      <Route path='/videoandgallery'element={<GalleryPage/>}/>
  
          <Route path='*'element={<HomePage/>}/>
     </Routes>
     <Footer/>
     </BrowserRouter>
    }
   </>
  );
}

export default App;
