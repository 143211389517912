import { useEffect, useRef } from "react";
import $ from 'jquery';

function Preloader() {
    const vidref=useRef();

    useEffect(() => { 
        var windowHeight = $(window).height();
        $('.preloader .Video').height(windowHeight+200);
        vidref.current.play();
    
    },[]);

    return <div className="preloader" id="preloader">
            <video className="Video" ref={ vidref } src={require("../Assets/preloader.mp4")}playsInline autoPlay loop muted />
    </div>
}
export default Preloader;