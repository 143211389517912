import Header from "../Components/Header";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Fancybox from "../Components/Fancybox";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { useTranslation } from "react-i18next";

import { Autoplay ,Navigation } from 'swiper/modules';
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function STANDARD(params){
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, [])
    const [cc,Setcc]=useState(1);

    return<>
       <Header  head="STANDARD PLUS"bg="uploads/2022/04/TH_3068498_21006498_21005498_21004498_21003498_001_800_800-1.jpg" paragraph={t("STANDARD_p")} />
       <div className="brousore flex flex-2   mr-100" >
    
    <div className="header ">
   <h1><span>|</span> {t("category")} {t("details")}</h1>
    <Fancybox
  options={{
    Carousel: {
      infinite: false,
    },
  }}
>
    
<Swiper
  slidesPerView={cc}
  centeredSlides={true}
  pagination={{
    clickable: true,
    
  }}
  autoplay={{
      delay: 1000,
      disableOnInteraction: false,
    }}
    
  loop
  navigation={true}
  modules={[ Autoplay,Navigation ]}
  className="mySwiper-1"
><SwiperSlide>
  <a data-fancybox="gallery"data-caption="IN_HP_RUBY70" href={require("../Assets/uploads/2022/05/IN_HP_Standard_Plus_21003479_001-1.jpg")}>
    <img className="gallery" src={require("../Assets/uploads/2022/05/IN_HP_Standard_Plus_21003479_001-1.jpg")} width="200" height="150" />
  </a></SwiperSlide>
  </Swiper> 
</Fancybox>

    </div>
    <div className="item  dimensions">
    <Tabs
      defaultActiveKey="KEYFEATURES"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="KEYFEATURES" title={t("KEY_FEATURES")}>



      <ul><li data-aos="fade-up" data-aos-duration="500">{t("STANDARD_KEY_FEATURES_d1")}</li>
      <li data-aos="fade-up" data-aos-duration="500">{t("STANDARD_KEY_FEATURES_d2")}</li>
      <li data-aos="fade-up" data-aos-duration="500">{t("STANDARD_KEY_FEATURES_d3")}</li></ul>      </Tab>
      <Tab eventKey="TECHNICALSPECIFICATIONS" title={t("TECHNICAL_SPECIFICATIONS")}>
<ul><li><strong>{t("Product_type")}:</strong> {t("producttypep")}</li>
<li><strong>{t("Commercial_classification")}:&nbsp;</strong> {t("Commercial_classification_p")}</li>
<li><strong>{t("Total_thickness")}:</strong>&nbsp;3 mm</li>
<li><strong>{t("Wear_layer_thickness")}:&nbsp;</strong>0,70 mm</li>
<li><strong>{t("Total_weight")}:</strong>&nbsp;2500 g/m²</li></ul>    
  </Tab>
    </Tabs>
    </div>
   
    </div>
<div className="brousore flex flex-3 mr-100">
    
<div className="header ">
   <h1><span>|</span> {t("our documents")}</h1>
</div>


<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>DOP</h2>
    <a href={require("../Assets/uploads/2022/04/dop.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>DATASHEET</h2>
    <a href={require("../Assets/uploads/2022/04/datasheet.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>MAINTENANCE</h2>
    <a href={require("../Assets/uploads/2022/04/Maintenance.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>ENVIRONMENTAL PRODUCT DECLARATION</h2>
    <a href={require("../Assets/uploads/2022/04/environmental-product.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>MATERIAL HEALTH STATEMENT</h2>
    <a href={require("../Assets/uploads/2022/04/Material-health-statement-1.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>INSTALLATION</h2>
    <a href={require("../Assets/uploads/2022/04/installation.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>INSTALLATION2</h2>
    <a href={require("../Assets/uploads/2022/04/installation-standard2.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
</div>
    

    </>
}