import { useEffect, useRef } from "react";
import $ from 'jquery';

function HomeSlider () {
    const vidref=useRef();

    useEffect(() => { 
        var windowHeight = $(window).height();
        $('.slider').height(windowHeight);
        vidref.current.play();
    
    },[]);

    return ( <>

    <div className='slider'>
    <video className="Video" ref={ vidref }  src={require("../../Assets/slide.mp4")} playsInline loop autoPlay  muted />
    </div>

    
    </> );
}

export default HomeSlider ;