import Header from "../Components/Header";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
export default function EadgeOption() {
        const { t } = useTranslation();
        useEffect(() => {
                AOS.init();
              }, [])
    return<>
       <Header  head={t("staron")+" "+t("Edge_Options")}bg="service/IN_HP_Primo_SD_21012561_001.jpg" paragraph={t("staronedge")}/>
        <section  className=" flex mr-100">
                
            <div  data-aos="fade-up"
     data-aos-easing="ease-in-back"
    data-aos-duration="700"
     data-aos-offset="0" className="Edge ">
<div  className="image">
        <img decoding="async" src={require("../Assets/uploads/2022/03/staron_img01.jpg")}/>
        </div>
        <h4>Half Bullnose</h4>
</div>
<div  data-aos="fade-up"
     data-aos-easing="ease-in-back"
    data-aos-duration="700"
     data-aos-offset="0" className="Edge ">
<div  className="image">
        <img decoding="async" src={require("../Assets/uploads/2022/03/staron_img02.jpg")}/>
        </div>
        <h4>FULL BULLNOSE</h4>
</div>
<div  data-aos="fade-up"
     data-aos-easing="ease-in-back"
    data-aos-duration="700"
     data-aos-offset="0" className="Edge ">
<div  className="image">
        <img decoding="async" src={require("../Assets/uploads/2022/03/staron_img03.jpg")}/>
        </div>
        <h4>DEMI BULLNOSE
</h4>
</div>
<div  data-aos="fade-up"
     data-aos-easing="ease-in-back"
    data-aos-duration="700"
     data-aos-offset="0" className="Edge ">
<div  className="image">
        <img decoding="async" src={require("../Assets/uploads/2022/03/staron_img04.jpg")}/>
        </div>
        <h4>OGEE</h4>
</div>

<div  data-aos="fade-up"
     data-aos-easing="ease-in-back"
    data-aos-duration="700"
     data-aos-offset="0" className="Edge ">
<div  className="image">
        <img decoding="async" src={require("../Assets/uploads/2022/03/staron_img05.jpg")}/>
        </div>
        <h4>COVE OGEE
</h4>
</div>
<div  data-aos="fade-up"
     data-aos-easing="ease-in-back"
    data-aos-duration="700"
     data-aos-offset="0" className="Edge ">
<div  className="image">
        <img decoding="async" src={require("../Assets/uploads/2022/03/staron_img06.jpg")}/>
        </div>
        <h4>STRAIGHT
</h4>
</div>
<div  data-aos="fade-up"
     data-aos-easing="ease-in-back"
    data-aos-duration="700"
     data-aos-offset="0" className="Edge ">
<div  className="image">
        <img decoding="async" src={require("../Assets/uploads/2022/03/staron_img07.jpg")}/>
        </div>
        <h4>SRAIR THREAD
</h4>
</div>
<div  data-aos="fade-up"
     data-aos-easing="ease-in-back"
    data-aos-duration="700"
     data-aos-offset="0" className="Edge ">
<div  className="image">
        <img decoding="async" src={require("../Assets/uploads/2022/03/staron_img08.jpg")}/>
        </div>
        <h4>WATERFALL
</h4>
</div>

<div  data-aos="fade-up"
     data-aos-easing="ease-in-back"
    data-aos-duration="700"
     data-aos-offset="0" className="Edge ">
<div  className="image">
        <img decoding="async" src={require("../Assets/uploads/2022/03/staron_img09.jpg")}/>
        </div>
        <h4>SINGLE IMPERIAL
</h4>
</div>
<div  data-aos="fade-up"
     data-aos-easing="ease-in-back"
    data-aos-duration="700"
     data-aos-offset="0" className="Edge ">
<div  className="image">
        <img decoding="async" src={require("../Assets/uploads/2022/03/staron_img10.jpg")}/>
        </div>
        <h4>5MM ROUND
</h4>
</div>
<div  data-aos="fade-up"
     data-aos-easing="ease-in-back"
    data-aos-duration="700"
     data-aos-offset="0" className="Edge ">
<div  className="image">
        <img decoding="async" src={require("../Assets/uploads/2022/03/staron_img11.jpg")}/>
        </div>
        <h4>10MM BEVEL
</h4>
</div>
<div  data-aos="fade-up"
     data-aos-easing="ease-in-back"
    data-aos-duration="700"
     data-aos-offset="0" className="Edge ">
<div  className="image">
        <img decoding="async" src={require("../Assets/uploads/2022/03/staron_img12.jpg")}/>
        </div>
        <h4>5MM ROUND T&B
</h4>
</div>
</section>
    </>
}