
import ContactForm from '../Components/ContactForm';
import Information from '../Components/Information';
function Contact () {
    return ( 
    <div className='contact'>
        <div className='container-flu'>
        <Information/>
        <ContactForm/>
        </div>
      

    
    </div> );
}

export default Contact ;