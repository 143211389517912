import HomeSlider from "../Components/Home/HomeSlider";
import OurBrand from "../Components/Home/OurBrand";
import Service from "../Components/Home/Service";
import HomeSection from "../Components/HomeSection";
import Preloader from "../Components/Preloader";

function HomePage(params) {

    return <>
        <HomeSlider/>
        <div className="homeserv">


        <Service/>
        <OurBrand/>
        
<div className="leftpatern"></div>
 <div className="rightpatern"></div>
 {/* <HomeSection/> */}

</div>
    </>
}
export default HomePage;