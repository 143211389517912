import Header from "../Components/Header";
import { useTranslation } from "react-i18next";

export default function RadProductDimensions() {
const { t } = useTranslation();

    return<>
        
        <Header  head={t("Radianz")+" "+t("product_dimention")}bg="service/IN_HP_Primo_SD_21012561_001.jpg" paragraph=""/>
        <div className="production" style={{backgroundImage: 'url('+require("../Assets/uploads/2022/04/1635838946_cdeff4cb47a6312a7b6e-800x419.jpg")+')'}}></div>
    </>
}