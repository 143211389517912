import Gallery from "./Gallery";
import Video from "./Vidios";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import StaronGallery from "./StaronGallery";

export default function StaronGalleryPage(params) {
    const { t } = useTranslation();

    return<>
           
            <StaronGallery/>
            <Video/>
    </>
}