import Header from "../Components/Header";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
export default function StaronGallery() {
        const { t } = useTranslation();
        useEffect(() => {
                AOS.init();
              }, [])
    return<>
        <section className=" flex hpl mr-200">
        <div className="header wd-100" >
                <h1><span>|</span> {t("galary")}</h1>
                </div>
        <div className="header wd-100" >
   <h1><span>|</span> {t("galaryandvideo")}</h1>
</div>
            <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/service/staron/269603218_917652298956064_836429021964274471_n.jpg")})`}} >
                <div className="shadow">
                <h4></h4>
                        </div>              
</div>
 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/service/staron/375575469_635258405461826_8448566758848584713_n.jpg")})`}} >
     <div className="shadow">
     <h4></h4>
             </div>              
</div>

<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="800"  style={{backgroundImage: `url(${require("../Assets/service/staron/382489869_644645247856475_5514233316598964056_n.jpg")})`}} >
     <div className="shadow">
     <h4></h4>
             </div>              
</div>


<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/service/staron/382994626_644608954526771_7702301332946979159_n.jpg")})`}} >
     <div className="shadow">
     <h4></h4>
             </div>              
</div>

<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/service/staron/382994685_644604374527229_7191409569955122554_n.jpg")})`}} >
                <div className="shadow">
                <h4></h4>
                        </div>              
</div>

 <div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="600" style={{backgroundImage: `url(${require("../Assets/service/staron/6.png")})`}} >
     <div className="shadow">
     <h4></h4>
             </div>              
</div>

<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/service/staron/8.jpg")})`}} >
     <div className="shadow">
     <h4></h4>
             </div>              
</div>

<div className="Edge" data-aos="fade-up"
     data-aos-easing="linear"data-aos-duration="500" style={{backgroundImage: `url(${require("../Assets/service/staron/x.jpg")})`}} >
     <div className="shadow">
     <h4></h4>
             </div>              
</div>
</section>
    </>
}