import Header from "../Components/Header";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
export default function RadEadgeOption() {
        const { t } = useTranslation();
        useEffect(() => {
                AOS.init();
              }, [])
    return<>
       <Header  head={t("Radianz")+" "+t("Edge_Options")}bg="service/IN_HP_Primo_SD_21012561_001.jpg" paragraph={t("rezedge")}/>
        <section class=" flex mr-100">
            <div className="wd-100">
<h1>MOST POPULAR EDGES</h1>
            </div>
            <div data-aos="fade-up"
     data-aos-duration="500" className="Edge ">
<div class="image">
        <img decoding="async" src={require("../Assets/uploads/2022/04/radianz_popular_img01.jpg")}/>
        </div>
        <h4>STRAIGHT</h4>
</div>
<div data-aos="fade-up"
     data-aos-duration="600" className="Edge ">
<div class="image">
        <img decoding="async" src={require("../Assets/uploads/2022/04/radianz_popular_img02.jpg")}/>
        </div>
        <h4>6MM ROUND</h4>
</div>
<div data-aos="fade-up"
     data-aos-duration="700" className="Edge ">
<div class="image">
        <img decoding="async" src={require("../Assets/uploads/2022/04/radianz_popular_img03.jpg")}/>
        </div>
        <h4>BEVEL
</h4>
</div>
<div data-aos="fade-up"
     data-aos-duration="800" className="Edge ">
<div class="image">
        <img decoding="async" src={require("../Assets/uploads/2022/04/radianz_popular_img04.jpg")}/>
        </div>
        <h4>ROUND T&B</h4>
</div>

<div data-aos="fade-up"
     data-aos-duration="500" className="Edge ">
<div class="image">
        <img decoding="async" src={require("../Assets/uploads/2022/04/radianz_popular_img05.jpg")}/>
        </div>
        <h4>HALF BULL-NOSE

</h4>
</div>
<div data-aos="fade-up"
     data-aos-duration="600" className="Edge ">
<div class="image">
        <img decoding="async" src={require("../Assets/uploads/2022/04/radianz_popular_img06.jpg")}/>
        </div>
        <h4>6 BEVEL T&B

</h4>
</div>
<div data-aos="fade-up"
     data-aos-duration="700" className="Edge ">
<div class="image">
        <img decoding="async" src={require("../Assets/uploads/2022/04/radianz_popular_img07.jpg")}/>
        </div>
        <h4>FULL BULL-NOSE

</h4>
</div>
<div data-aos="fade-up"
     data-aos-duration="800" className="Edge ">
<div class="image">
        <img decoding="async" src={require("../Assets/uploads/2022/04/radianz_popular_img08.jpg")}/>
        </div>
        <h4>DEMI BULLNOSE

</h4>
</div>
<div className="wd-100">
<h1>MOST POPULAR EDGES</h1>
            </div>
<div data-aos="fade-up"
     data-aos-duration="500" className="Edge ">
<div class="image">
        <img decoding="async" src={require("../Assets/uploads/2022/04/radianz_premium_img01.jpg")}/>
        </div>
        <h4>OGEE
</h4>
</div>
<div data-aos="fade-up"
     data-aos-duration="600" className="Edge ">
<div class="image">
        <img decoding="async" src={require("../Assets/uploads/2022/04/radianz_premium_img02.jpg")}/>
        </div>
        <h4>STAIR THREAD
</h4>
</div>
<div data-aos="fade-up"
     data-aos-duration="700" className="Edge ">
<div class="image">
        <img decoding="async" src={require("../Assets/uploads/2022/04/radianz_premium_img03.jpg")}/>
        </div>
        <h4>WATERFALL
</h4>
</div>
<div data-aos="fade-up"
     data-aos-duration="800" className="Edge ">
<div class="image">
        <img decoding="async" src={require("../Assets/uploads/2022/04/radianz_premium_img04.jpg")}/>
        </div>
        <h4>COVE OGEE
</h4>
</div>
</section>
    </>
}