import Header from "../Components/Header";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Fancybox from "../Components/Fancybox";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Autoplay ,Navigation } from 'swiper/modules';
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function ECLIPSE(params) {
    const [cc,Setcc]=useState(1);
    const { t } = useTranslation();
    useEffect(() => {
      AOS.init();
    }, [])
    return<>
       <Header  head="ECLIPSE PREMIUM"bg="uploads/2022/04/IN_HP_Eclipse_Premium_21020968_21020967_001-1-1536x864.jpg" paragraph={t("Eclipse_Premium_p")} />
       <div className="brousore flex flex-2   mr-100" >
    
    <div className="header ">
   <h1><span>|</span> {t("category")} {t("details")}</h1>
    <Fancybox
  options={{
    Carousel: {
      infinite: true,
    },
  }}
>
    
<Swiper
  slidesPerView={cc}
  centeredSlides={true}
  pagination={{
    clickable: true,
    
  }}
  autoplay={{
      delay: 2000,
      disableOnInteraction: false,
    }}
    
  loop
  navigation={true}
  modules={[ Autoplay,Navigation ]}
  className="mySwiper-1"
><SwiperSlide>
  <a data-fancybox="gallery"data-caption="IN_PREMIUM" href={require("../Assets/uploads/2022/04/IN_PREMIUM_21081078_21010600_001.jpg")}>
    <img className="gallery" src={require("../Assets/uploads/2022/04/IN_PREMIUM_21081078_21010600_001.jpg")} width="200" height="150" />
  </a></SwiperSlide>
  <SwiperSlide>
  <a data-fancybox="gallery"data-caption="IN_PREMIUM" href={require("../Assets/uploads/2022/04/IN_PREMIUM_21010674_21020707_21081070_001.jpg")}>
    <img className="gallery" src={require("../Assets/uploads/2022/04/IN_PREMIUM_21010674_21020707_21081070_001.jpg")} width="200" height="150" />
  </a></SwiperSlide>
  <SwiperSlide>
  <a data-fancybox="gallery"data-caption="IN_PREMIUM" href={require("../Assets/uploads/2022/04/IN_HP_Eclipse_Premium_21020968_21020967_001-1-1536x864.jpg")}>
    <img className="gallery" src={require("../Assets/uploads/2022/04/IN_HP_Eclipse_Premium_21020968_21020967_001-1-1536x864.jpg")} width="200" height="150" />
  </a></SwiperSlide>
  <SwiperSlide>
  <a data-fancybox="gallery"data-caption="IN_PREMIUM" href={require("../Assets/uploads/2022/04/IN_PREMIUM_21020708_21081079_001.jpg")}>
    <img className="gallery" src={require("../Assets/uploads/2022/04/IN_PREMIUM_21020708_21081079_001.jpg")} width="200" height="150" />
  </a></SwiperSlide>
  <SwiperSlide>
  <a data-fancybox="gallery"data-caption="IN_PREMIUM" href={require("../Assets/uploads/2022/04/IN_PREMIUM_21010656_21010667_21010678_001.jpg")}>
    <img className="gallery" src={require("../Assets/uploads/2022/04/IN_PREMIUM_21010656_21010667_21010678_001.jpg")} width="200" height="150" />
  </a></SwiperSlide>
  <SwiperSlide>
  <a data-fancybox="gallery"data-caption="IN_PREMIUM_21020972_21020669_21020775" href={require("../Assets/uploads/2022/04/IN_PREMIUM_21020972_21020669_21020775_001-768x432.jpg")}>
    <img className="gallery" src={require("../Assets/uploads/2022/04/IN_PREMIUM_21020972_21020669_21020775_001-768x432.jpg")} width="200" height="150" />
  </a></SwiperSlide>
  </Swiper> 
</Fancybox>

    </div>
    <div className="item  dimensions">
    <Tabs
      defaultActiveKey="KEYFEATURES"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="KEYFEATURES" title={t("KEY_FEATURES")}>



<ul>
    <li  data-aos="fade-up" data-aos-duration="500">{t("ECLIPSE_KEY_FEATURES_d1")}</li>
    <li  data-aos="fade-up" data-aos-duration="600">{t("ECLIPSE_KEY_FEATURES_d2")}</li>
    <li data-aos="fade-up" data-aos-duration="700">{t("ECLIPSE_KEY_FEATURES_d3")}</li>
    <li  data-aos="fade-up" data-aos-duration="800">{t("ECLIPSE_KEY_FEATURES_d4")}</li>
    <li  data-aos="fade-up" data-aos-duration="900">{t("ECLIPSE_KEY_FEATURES_d5")}</li>
    <li  data-aos="fade-up" data-aos-duration="1000">{t("ECLIPSE_KEY_FEATURES_d6")}</li>
</ul>      </Tab>
      <Tab eventKey="TECHNICALSPECIFICATIONS" title={t("TECHNICAL_SPECIFICATIONS")}>
<ul><li><strong>{t("Product_type")}:</strong> {t("producttypep")}</li>
<li><strong>{t("Commercial_classification")}:&nbsp;</strong> {t("Commercial_classification_p")}</li>
<li><strong>{t("Total_thickness")}:</strong>&nbsp;3 mm</li>
<li><strong>{t("Wear_layer_thickness")}:&nbsp;</strong>0,70 mm</li>
<li><strong>{t("Total_weight")}:</strong>&nbsp;2500 g/m²</li></ul>    
  </Tab>
    </Tabs>
    </div>
   
    </div>
<div className="brousore flex flex-3 mr-100">
    
<div className="header ">
   <h1><span>|</span> {t("our documents")}</h1>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>BROCHURE</h2>
    <a href={require("../Assets/uploads/2022/04/brochure1-1.pdf")}  target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>BROCHURE</h2>
    <a href={require("../Assets/uploads/2022/04/brochure2.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>COVER</h2>
    <a href={require("../Assets/uploads/2022/04/IN_HP_Eclipse_Premium_21020968_21020967_001-scaled.jpg")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>DOP</h2>
    <a href={require("../Assets/uploads/2022/04/dop.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>DATASHEET</h2>
    <a href={require("../Assets/uploads/2022/04/datasheet.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>MAINTENANCE</h2>
    <a href={require("../Assets/uploads/2022/04/Maintenance.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>ENVIRONMENTAL PRODUCT DECLARATION</h2>
    <a href={require("../Assets/uploads/2022/04/environmental-product.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>MATERIAL HEALTH STATEMENT</h2>
    <a href={require("../Assets/uploads/2022/04/Material-health-statement-1.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>SPECIFICATION MODEL</h2>
    <a href={require("../Assets/uploads/2022/04/Specification-model.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
<div     data-aos="fade-up"
     data-aos-duration="500" className="justfy_center item bord">
    <FontAwesomeIcon icon="fa-solid fa-download" />
    <h2>INSTALLATION</h2>
    <a href={require("../Assets/uploads/2022/04/installation.pdf")}target="blank">{t("download")}<FontAwesomeIcon icon="fa-solid fa-arrow-right" /></a>
</div>
</div>
    

    </>
}