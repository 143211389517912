import Header from "../Components/Header";
import { useTranslation } from "react-i18next";
export default function ProductDimensions() {
    const { t } = useTranslation();

return<>
        
        <Header  head={t("staron")+" "+t("product_dimention")}bg="service/IN_HP_Primo_SD_21012561_001.jpg" paragraph=""/>
        <div className="production" style={{backgroundImage:  'url('+require("../Assets/uploads/2022/03/1631514922_c7986db2c2dad93208c3-1.png")+')'}}></div>
    </>
}