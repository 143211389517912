import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from "react-i18next";

export default function ReadianzDocumentation(params) {
        const { t } = useTranslation();

    return<div className="documentation"> 
    <Tabs
        defaultActiveKey="ProductOffering"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
<Tab eventKey="ProductOffering" title={t("Product_Offering")}>
        <div class="grid-container">
  <div class="wp-block-file"><a id="wp-block-file--media-8f29383d-099f-4f56-94a1-53589a38574e" href={require("../Assets/uploads/2022/04/Product-dimension.pdf")}>Product Dimension</a><a href={require("../Assets/uploads/2022/04/Product-dimension.pdf")}class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-8f29383d-099f-4f56-94a1-53589a38574e">Download</a></div>
  </div>
        </Tab>
        <Tab eventKey="TechnicalBulletin" title={t("Technical_Bulletin")}>
        <div class="grid-container">
<div class="wp-block-file"><a id="wp-block-file--media-a636fa2d-113a-4070-96df-f3d7337c82ca" href={require("../Assets/uploads/2022/04/Specification-form-RADIANZ-QUARTZ-Flooring-09-63-40.pdf")}>Specification form RADIANZ QUARTZ Flooring 09 63 40</a><a href={require("../Assets/uploads/2022/04/Specification-form-RADIANZ-QUARTZ-Flooring-09-63-40.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-a636fa2d-113a-4070-96df-f3d7337c82ca">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-50caa204-d1d5-420a-80f2-4df2f8107996" href={require("../Assets/uploads/2022/04/Specification-form-RADIANZ-quartz-surface-counter-top-06-61-19.pdf")}>Specification formSpecification form RADIANZ quartz surface counter top 06 61 19</a><a href={require("../Assets/uploads/2022/04/Specification-form-RADIANZ-quartz-surface-counter-top-06-61-19.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-50caa204-d1d5-420a-80f2-4df2f8107996">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-f500d772-08a1-46c8-a2ca-2c2392916dfe" href={require("../Assets/uploads/2022/04/Chemical-Resistance.pdf")} >Chemical Resistance</a><a href={require("../Assets/uploads/2022/04/Chemical-Resistance.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-f500d772-08a1-46c8-a2ca-2c2392916dfe">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-ebb15a9b-5e7c-4c16-bda9-d3ed6c6c3b0a" href={require("../Assets/uploads/2022/04/Performance-Properties-EN.pdf")} >Performance Properties-EN</a><a href={require("../Assets/uploads/2022/04/Performance-Properties-EN.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-ebb15a9b-5e7c-4c16-bda9-d3ed6c6c3b0a">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-0da65ef9-3c88-42b8-8039-03547e29e2d4" href={require("../Assets/uploads/2022/04/Performance-Properties-ASTM.pdf")} >Performance Properties-ASTM</a><a href={require("../Assets/uploads/2022/04/Performance-Properties-ASTM.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-0da65ef9-3c88-42b8-8039-03547e29e2d4">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-25b7e4f3-1a20-49d2-a971-772e321fd6f4" href={require("../Assets/uploads/2022/04/LRV-of-Radianz.pdf")} >LRV of Radianz</a><a href={require("../Assets/uploads/2022/04/LRV-of-Radianz.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-25b7e4f3-1a20-49d2-a971-772e321fd6f4">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-d9fc7810-7df1-4c19-8989-d56823ea0272" href={require("../Assets/uploads/2022/04/Recycled-Series.pdf")} >Recycled Series</a><a href={require("../Assets/uploads/2022/04/Recycled-Series.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-d9fc7810-7df1-4c19-8989-d56823ea0272">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-981d799c-dae1-46f0-94c3-af493873e124" href={require("../Assets/uploads/2022/04/Crack-prevention-case-study.pdf")} >Crack prevention case study</a><a href={require("../Assets/uploads/2022/04/Crack-prevention-case-study.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-981d799c-dae1-46f0-94c3-af493873e124">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-fc0c44f9-1777-4ed6-81ea-be88fc606b10" href={require("../Assets/uploads/2022/04/Characteristics-of-Matte-finished-product.pdf")} >Characteristics of Matte finished product</a><a href={require("../Assets/uploads/2022/04/Characteristics-of-Matte-finished-product.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-fc0c44f9-1777-4ed6-81ea-be88fc606b10">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-f793f0ef-9bdd-4d1e-99c4-6b36c789439f" href={require("../Assets/uploads/2022/04/Characteristics-of-Cirrus-and-coastal-collection.pdf")} >Characteristics of Cirrus and coastal collection</a><a href={require("../Assets/uploads/2022/04/Characteristics-of-Cirrus-and-coastal-collection.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-f793f0ef-9bdd-4d1e-99c4-6b36c789439f">Download</a></div>
</div>
        </Tab>
        <Tab eventKey="FabricationManual" title={t("Fabrication_Manual")}>
        <div class="grid-container">
<div class="wp-block-file"><a id="wp-block-file--media-852a4249-ccad-412f-bdbb-9cffa23b7211" href={require("../Assets//uploads/2022/04/Radianz-Floor-Installation.pdf")} >Radianz Floor Installation</a><a href={require("../Assets//uploads/2022/04/Radianz-Floor-Installation.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-852a4249-ccad-412f-bdbb-9cffa23b7211">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-655198ae-0b2a-46e6-aa9e-2ddd65de9f3d" href={require("../Assets//uploads/2022/04/Fabrication-Manual.pdf")} >Fabrication Manual</a><a href={require("../Assets//uploads/2022/04/Fabrication-Manual.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-655198ae-0b2a-46e6-aa9e-2ddd65de9f3d">Download</a></div>
</div>
        </Tab>
        <Tab eventKey="Sustainability&Certification" title={t("Sustainability & Certification")}>
        <div class="grid-container">
<div class="wp-block-file"><a id="wp-block-file--media-a6a84c04-fb4c-49df-9a91-4fe2fed25de4" href={require("../Assets//uploads/2022/04/GREENGUARD-GOLD.pdf")} >GREENGUARD GOLD</a><a href={require("../Assets//uploads/2022/04/GREENGUARD-GOLD.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-a6a84c04-fb4c-49df-9a91-4fe2fed25de4">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-94894d2c-05d3-4e5f-9710-2dfd94044956" href={require("../Assets//uploads/2022/04/HPDHealth-Product-declaration.pdf")} >HPDHealth Product declaration</a><a href={require("../Assets//uploads/2022/04/HPDHealth-Product-declaration.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-94894d2c-05d3-4e5f-9710-2dfd94044956">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-42e07612-402a-4061-b816-7a1beff3eaa6" href={require("../Assets//uploads/2022/04/SCS-recycled-product.pdf")} >SCS recycled product</a><a href={require("../Assets//uploads/2022/04/SCS-recycled-product.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-42e07612-402a-4061-b816-7a1beff3eaa6">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-0e9cc9cc-8260-4305-ae3b-fb76dfcbf49b" href={require("../Assets//uploads/2022/04/Management-systemISO-9001-14001-27001-50001-OHSAS-18001.pdf")} >Management system(ISO 9001,14001,27001,50001,OHSAS 18001</a><a href={require("../Assets//uploads/2022/04/Management-systemISO-9001-14001-27001-50001-OHSAS-18001.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-0e9cc9cc-8260-4305-ae3b-fb76dfcbf49b">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-d6a2f621-019f-47b3-a896-5534857cd4b2" href={require("../Assets//uploads/2022/04/GREENGUARD.pdf")} >GREENGUARD</a><a href={require("../Assets//uploads/2022/04/GREENGUARD.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-d6a2f621-019f-47b3-a896-5534857cd4b2">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-4f92f5f4-5ccd-4a3d-a277-7818bd5ed240" href={require("../Assets//uploads/2022/04/NSF-ANSI-51.pdf")} >NSF ANSI 51</a><a href={require("../Assets//uploads/2022/04/NSF-ANSI-51.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-4f92f5f4-5ccd-4a3d-a277-7818bd5ed240">Download</a></div>
</div>
        </Tab>
        <Tab eventKey="Warranty&Care" title={t("Warranty&Care")}>
        <div class="grid-container">
<div class="wp-block-file"><a id="wp-block-file--media-471d418e-09a8-4f62-b789-7b23c3961086" href={require("../Assets//uploads/2022/04/Commercial-Warranty.pdf")} >Commercial Warranty</a><a href={require("../Assets//uploads/2022/04/Commercial-Warranty.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-471d418e-09a8-4f62-b789-7b23c3961086">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-9e242981-e26a-4119-a8c9-6716d6981caa" href={require("../Assets//uploads/2022/04/Residential-Warranty.pdf")} >Residential Warranty</a><a href={require("../Assets//uploads/2022/04/Residential-Warranty.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-9e242981-e26a-4119-a8c9-6716d6981caa">Download</a></div>



<div class="wp-block-file"><a id="wp-block-file--media-aafe47fe-c863-41a0-b658-99203f4140e4" href={require("../Assets//uploads/2022/04/Care-and-maintenance.pdf")} >Care and maintenance</a><a href={require("../Assets//uploads/2022/04/Care-and-maintenance.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-aafe47fe-c863-41a0-b658-99203f4140e4">Download</a></div>
</div>
        </Tab>
        <Tab eventKey="MSDS" title={t("MSDS")}>
        <div class="grid-container">
<div class="wp-block-file"><a id="wp-block-file--media-94cdca4a-8971-4a24-9b8f-2b6bbbe3d377" href={require("../Assets//uploads/2022/04/MSDS-Radianz.pdf")} >MSDS Radianz</a><a href={require("../Assets//uploads/2022/04/MSDS-Radianz.pdf")} class="wp-block-file__button" download="" aria-describedby="wp-block-file--media-94cdca4a-8971-4a24-9b8f-2b6bbbe3d377">Download</a></div>
</div>
        </Tab>
      </Tabs>
      </div >
    
}