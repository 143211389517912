import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';import { Link } from 'react-router-dom';
import Header from "../Components/Header";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
export default function AksCategory(params) {
        const { t } = useTranslation();
        useEffect(() => {
                AOS.init();
              }, [])
        return<>
           <Header  head={t("WALL_AND_CORNER_PROTECTION")}bg="service/IN_HP_Primo_SD_21012561_001.jpg" paragraph={t("WALL_AND_CORNER_PROTECTION_p")}/>
    
    <div className="documentation category mr-100 "> 
    <Tabs
        defaultActiveKey="all"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="all" title="all">
        <section className=" flex ">
            <div data-aos="fade-up"
     data-aos-duration="500" className="Edge">
                <Link to="/akscategory/1" class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dk-142-1-768x576.jpg")}/>
                        </Link>
                        <Link to="/akscategory/1">DK. 142 WALL PROTECTION BARRIER</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="600" className="Edge">
                <Link to="/akscategory/2" class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dk-1500-1-768x576.jpg")}/>
                        </Link>
                        <Link to="/akscategory/2">DK.1500 WALL PROTECTION BARRIER</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="700" className="Edge">
                <Link to="/akscategory/3"class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dd-152-1-768x576.jpg")}/>
                        </Link>
                        <Link to="/akscategory/3">DD.152 WALL PROTECTION BARRIER HANDRAIL</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="800" className="Edge">
                <Link to="/akscategory/4"class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dk-200-1-768x576.jpg")}/>
                        </Link>
                        <Link to="/akscategory/4">DK. 200 WALL PROTECTION BARRIER</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="500" className="Edge">
                <Link to="/akscategory/5"class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dk-150-1-768x576.jpg")}/>
                        </Link>
                        <Link to="/akscategory/5">DK. 150 WALL PROTECTION BARRIER</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="600" className="Edge">
                <Link to="/akscategory/6"class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dk-100-1-500x375.jpg")}/>
                        </Link>
                        <Link to="/akscategory/6">DK. 100 WALL PROTECTION BARRIER</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="700" className="Edge">
                <Link to="/akscategory/7"class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dk-50-1-500x375.jpg")}/>
                        </Link>
                        <Link to="/akscategory/7">DK. 50 WALL PROTECTION BARRIER</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="800" className="Edge">
                <Link to="/akscategory/8"class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/kk-45-1-768x576.jpg")}/>
                        </Link>
                        <Link to="/akscategory/8">KK.45 CORNER GUARD 
                        
                        </Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="500" className="Edge">
                <Link class="image"to="/akscategory/9">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/kk-55-1-500x375.jpg")}/>
                        </Link>
                        <Link to="/akscategory/9">KK.55 CORNER GUARD</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="600" className="Edge">
                <Link class="image"to="/akscategory/10">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/kk-76-1-500x375.jpg")}/>
                        </Link>
                        <Link to="/akscategory/10">KK.76 CORNER GUARD</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="700" className="Edge">
                <Link class="image"to="/akscategory/11">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/kk-76-135-1-500x375.jpg")}/>
                        </Link>
                        <Link to="/akscategory/11">KK.76-135 CORNER GUARD</Link>
                </div>
            </section>
        </Tab>
        <Tab eventKey="WITHHANDRAIL" title="WALL PROTECTION WITH HANDRAIL">
        <section class=" flex ">
        <div data-aos="fade-up"
     data-aos-duration="500" className="Edge">
                <Link to="/akscategory/1" class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dk-142-1-768x576.jpg")}/>
                        </Link>
                        <Link to="/akscategory/1">DK. 142 WALL PROTECTION BARRIER</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="600" className="Edge">
                <Link to="/akscategory/2" class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dk-1500-1-768x576.jpg")}/>
                        </Link>
                        <Link to="/akscategory/2">DK.1500 WALL PROTECTION BARRIER</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="700" className="Edge">
                <Link to="/akscategory/3"class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dd-152-1-768x576.jpg")}/>
                        </Link>
                        <Link to="/akscategory/3">DD.152 WALL PROTECTION BARRIER HANDRAIL</Link>
                </div>
                
            </section>
        </Tab>
        <Tab eventKey="WITHOUTHANDRAIL" title="WALL PROTECTION WITHOUT HANDRAIL">
        <section class=" flex ">
        <div data-aos="fade-up"
     data-aos-duration="800" className="Edge">
                <Link to="/akscategory/4"class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dk-200-1-768x576.jpg")}/>
                        </Link>
                        <Link to="/akscategory/4">DK. 200 WALL PROTECTION BARRIER</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="500" className="Edge">
                <Link to="/akscategory/5"class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dk-150-1-768x576.jpg")}/>
                        </Link>
                        <Link to="/akscategory/5">DK. 150 WALL PROTECTION BARRIER</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="600" className="Edge">
                <Link to="/akscategory/6"class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dk-100-1-500x375.jpg")}/>
                        </Link>
                        <Link to="/akscategory/6">DK. 100 WALL PROTECTION BARRIER</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="700" className="Edge">
                <Link to="/akscategory/7"class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/dk-50-1-500x375.jpg")}/>
                        </Link>
                        <Link to="/akscategory/7">DK. 50 WALL PROTECTION BARRIER</Link>
                </div>
               
            </section>
        </Tab>
        <Tab eventKey="CORNERGUARD" title="CORNER GUARD">
        <section class=" flex ">
        <div data-aos="fade-up"
     data-aos-duration="800" className="Edge">
                <Link to="/akscategory/8"class="image">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/kk-45-1-768x576.jpg")}/>
                        </Link>
                        <Link to="/akscategory/8">KK.45 CORNER GUARD 
                        
                        </Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="500" className="Edge">
                <Link class="image"to="/akscategory/9">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/kk-55-1-500x375.jpg")}/>
                        </Link>
                        <Link to="/akscategory/9">KK.55 CORNER GUARD</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="600" className="Edge">
                <Link class="image"to="/akscategory/10">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/kk-76-1-500x375.jpg")}/>
                        </Link>
                        <Link to="/akscategory/10">KK.76 CORNER GUARD</Link>
                </div>
                <div data-aos="fade-up"
     data-aos-duration="700" className="Edge">
                <Link class="image"to="/akscategory/11">
                        <img decoding="async" src={require("../Assets/uploads/2022/05/kk-76-135-1-500x375.jpg")}/>
                        </Link>
                        <Link to="/akscategory/11">KK.76-135 CORNER GUARD</Link>
                </div>
            </section>
        </Tab>
      </Tabs>
      </div >
    </>
}


