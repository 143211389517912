import { useEffect } from "react"
import $ from 'jquery';

export default function Header(params) {
    useEffect(()=>{
        var windowHeight = $(window).height();
        $('.HeaderSlider').height(windowHeight+300);
    })
    return<div className="HeaderSlider"style={{backgroundImage: `url(${require("../Assets/"+params.bg)})`}}>
        <div className="shadow">
            <h1>{params.head}</h1>
            <p>{params.paragraph}</p>
           
            </div>
            <div className="overlay"></div>
            <div className="overlay"></div>
            <div className="overlay"></div>
            <div className="overlay"></div>
    </div>
}